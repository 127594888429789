import {
  Box,
  Typography,
  Card,
  Dialog,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
  Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Text from 'src/components/Text';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeOffset } from 'src/models/client';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const EmergencyNotifModal = () => {
  const dispatch: AppDispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const emergencyNotification = useSelector(
    (state: RootState) => state?.announcements?.emergencyNotification
  );

  const onClose = () => {
    dispatch({ type: 'announcements/emergencyAcknowledge' });
  };

  useEffect(() => {
    setModalOpen(
      emergencyNotification && emergencyNotification.title ? true : false
    );
  }, [emergencyNotification]);

  return emergencyNotification && emergencyNotification.title ? (
    <Dialog
      open={modalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="md"
      onClose={onClose}
    >
      <React.Fragment>
        <Card
          sx={{
            padding: 2,
            maxHeight: '90vh',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
              maxHeight: '80vh',
              padding: 1
            }
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h2"
            textAlign={'center'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 3,
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              },
              whiteSpace: 'pre-line'
            }}
          >
            {emergencyNotification?.redirectURL ? (
              <NavLink
                to={emergencyNotification?.redirectURL}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Text color="info">{emergencyNotification.title}</Text>
              </NavLink>
            ) : (
              emergencyNotification.title
            )}
          </Typography>
          <Typography
            id="modal-modal-message"
            variant="h3"
            textAlign={'center'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 3,
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              },
              whiteSpace: 'pre-line'
            }}
          >
            {emergencyNotification?.redirectURL ? (
              <NavLink
                to={emergencyNotification?.redirectURL}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {emergencyNotification.message}
              </NavLink>
            ) : (
              emergencyNotification.message
            )}
          </Typography>
          <Box
            textAlign={'center'}
            display={'flex'}
            alignItems={'center'}
            alignContent={'center'}
            justifyItems={'center'}
            justifyContent={'center'}
            justifySelf={'center'}
            sx={{
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
              }
            }}
          >
            <Button color="success" size="large" onClick={onClose}>
              <CheckCircleIcon /> Dismiss
            </Button>
          </Box>
        </Card>
      </React.Fragment>
    </Dialog>
  ) : null;
};
