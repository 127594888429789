import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Paper,
  Avatar,
  Badge,
  Tooltip
} from '@mui/material';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import { DirectMessage, GroupMessage } from 'src/models/message';
import { DiscordDetails } from 'src/models/user';
import { OfferTypes } from 'src/models/offer';
import Messenger from 'src/content/applications/Messenger';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Text from 'src/components/Text';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import ConfirmButton from 'src/components/Utility/ConfirmationButton';

interface ChatBoxProps {
  messages: GroupMessage[] | DirectMessage[];
  userID: string;
  roomID: string;
  CachedDiscordDetails: { [key: string]: DiscordDetails };
}

const ChatBox: React.FC<ChatBoxProps> = ({
  messages,
  userID,
  roomID,
  CachedDiscordDetails
}) => {
  const dispatch = useDispatch();
  const [isMinimized, setIsMinimized] = useState(true);

  const toggleMinimize = () => {
    setIsMinimized((prev) => !prev);
  };

  const lastMessage = messages ? messages[messages?.length - 1] : null;
  const unreadmessages = messages
    ? [...messages]?.filter((message) => {
        return !message?.Readers?.includes(userID);
      })?.length ?? 0
    : 0;
  const chatName = lastMessage
    ? 'RoomID' in lastMessage
      ? lastMessage?.GroupName
      : CachedDiscordDetails?.[roomID]?.Username
    : '';
  const chatPictureURL = lastMessage
    ? 'RoomID' in lastMessage
      ? lastMessage.GroupPictureURL || OfferTypes[0].emoji
      : CachedDiscordDetails?.[roomID]?.AvatarURL
    : '';

  const onClose = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'maintained_room',
        data: {
          roomID: roomID,
          close: true
        },
        low_rate_limit: true
      }
    });
  };

  const handleMuteChange = () => {
    dispatch({
      type: 'notifications/mute',
      payload: { id: roomID, muted: true }
    });
  };

  return (
    <Paper
      elevation={3}
      sx={{
        width: isMinimized ? 300 : 500,
        height: isMinimized ? null : 400,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 1,
          backgroundColor: 'primary.dark',
          color: 'white',
          overflow: 'hidden',
          height: 50
        }}
      >
        <Box display="flex" alignItems="center">
          <Badge badgeContent={unreadmessages} color="error">
            <Avatar
              src={chatPictureURL}
              alt="avatar"
              sx={{ width: 30, height: 30, borderRadius: '50%' }}
            />
          </Badge>
          <Tooltip placement="top" title={'Mute and close this chat'}>
            <ConfirmButton
              confirmText={`Mute and close this chat? To unmute, you need to manually unmute this chat in the ${
                lastMessage && 'RoomID' in lastMessage
                  ? 'offer chat'
                  : ' their profile'
              }.`}
              sx={{
                color: 'error.main',
                marginLeft: -1,
                marginRight: -2
              }}
              onClick={() => {
                handleMuteChange();
              }}
              variant="text"
              size="small"
            >
              <NotificationsOffIcon />
            </ConfirmButton>
          </Tooltip>
        </Box>
        <Typography variant="h5" noWrap sx={{ maxWidth: 150 }}>
          {lastMessage && 'RoomID' in lastMessage ? (
            <NavLink
              to={`/management/offers/post?runID=${roomID}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Text color="info">{chatName}</Text>
            </NavLink>
          ) : (
            chatName
          )}
        </Typography>
        <Box>
          <IconButton size="small" onClick={toggleMinimize} color="inherit">
            <MinimizeIcon />
          </IconButton>
          <IconButton size="small" onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {!isMinimized && (
        <Box
          sx={{
            flex: 1,
            padding: 1,
            backgroundColor: 'background.paper',
            overflowY: 'auto'
          }}
        >
          <Messenger
            Messages={messages}
            MessagesType={
              lastMessage && 'RoomID' in lastMessage ? 'Group' : 'Direct'
            }
            ID={roomID}
            ChatName={chatName}
            ChatPictureURL={chatPictureURL}
          />
        </Box>
      )}
    </Paper>
  );
};

export default ChatBox;
