import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import socketReducer from 'src/features/socketSlice';
import userReducer from 'src/features/userSlice';
import leaderboardReducer from 'src/features/leaderboardsSlice';
import reviewReducer from 'src/features/reviewsSlice';
import appealReducer from 'src/features/appealsSlice';
import loginReducer from 'src/features/loginSlice';
import announcementsReducer from 'src/features/announcementsSlice';
import statisticsReducer from 'src/features/statisticsSlice';
import clientReducer from 'src/features/clientSlice';
import premiumInfoReducer from 'src/features/premiumInfoSlice';
import averageStatsReducer from 'src/features/averageStatsSlice';
import updateResponseReducer from 'src/features/updateResponseSlice';
import offerReducer from 'src/features/offerSlice';
import notificationsReducer from 'src/features/notificationsSlice';
import socketMiddleware from 'src/middlewares/socketMiddleware';
import userMiddleware from 'src/middlewares/userMiddleware';
import reviewMiddleware from 'src/middlewares/reviewMiddleware';
import loginMiddleware from 'src/middlewares/loginMiddleware';
import averageStatsMiddleware from 'src/middlewares/averageStatsMiddleware';
import updateResponseMiddleware from 'src/middlewares/updateResponseMiddleware';
import offerMiddleware from 'src/middlewares/offerMiddleware';
import notificationsMiddleware from 'src/middlewares/notificationMiddleware';
import clientMiddleware from 'src/middlewares/clientMiddleware';
import announcementsStatsMiddleware from 'src/middlewares/announcementsStatsMiddleware';

export const socetStore = configureStore({
  reducer: {
    socket: socketReducer,
    user: userReducer,
    reviews: reviewReducer,
    leaderboards: leaderboardReducer,
    appeals: appealReducer,
    login: loginReducer,
    announcements: announcementsReducer,
    client: clientReducer,
    statistics: statisticsReducer,
    premiumInfo: premiumInfoReducer,
    averageStats: averageStatsReducer,
    updateResponse: updateResponseReducer,
    offer: offerReducer,
    notifications: notificationsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(socketMiddleware)
      .concat(userMiddleware)
      .concat(reviewMiddleware)
      .concat(loginMiddleware)
      .concat(averageStatsMiddleware)
      .concat(updateResponseMiddleware)
      .concat(offerMiddleware)
      .concat(notificationsMiddleware)
      .concat(clientMiddleware)
      .concat(announcementsStatsMiddleware)
});

export type RootState = ReturnType<typeof socetStore.getState>;
export type AppDispatch = typeof socetStore.dispatch;

export default socetStore;
export const persistor = persistStore(socetStore);
