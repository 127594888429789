import { RootState } from 'src/app/store';
import { averageStats, findRelevantValue } from './averageStats';
import { DiscordDetails, User, getUser } from './user';
import { useSelector } from 'react-redux';
import { ReviewsString as getReviewsString } from './review';
import { MinimumOfferRequirements } from './autosignup';
import { Classes, WoWRoleType } from './wowclasses';
import { Character, MythicPlusCharacter } from './character';
import { RaidBosses } from './raidbosses';
import { Buyer } from './buyer';
import { WoWArmorType } from './wowarmors';
import { WoWTierToken } from './wowtiertokens';
import { PricelistItem } from './pricelist';
import { SignedupChar } from './signup';
import { signupFilters } from 'src/features/offerSlice';

export type OfferTypeNames =
  | 'MythicPlus'
  | 'MegaDungeon'
  | 'Leveling'
  | 'Mount'
  | 'Pet'
  | 'Achievement'
  | 'PvP'
  | 'Raid'
  | 'Miscellaneous'
  | 'Delve';
interface OfferType {
  name: OfferTypeNames;
  emoji: string;
  short: string;
}

export const OfferTypes: OfferType[] = [
  {
    name: 'MythicPlus',
    emoji: '/static/images/WoW/Emojis/chest.png',
    short: 'M+'
  },
  {
    name: 'MegaDungeon',
    emoji: '/static/images/WoW/Dungeons/DI.jpg',
    short: 'DoI'
  },
  {
    name: 'Leveling',
    emoji: '/static/images/WoW/Emojis/level_up.png',
    short: 'Leveling'
  },
  {
    name: 'Delve',
    emoji: '/static/images/WoW/Emojis/delve.png',
    short: 'Delve'
  },
  {
    name: 'Raid',
    emoji: '/static/images/WoW/Emojis/raid.png',
    short: 'Raid'
  },
  {
    name: 'PvP',
    emoji: '/static/images/WoW/Emojis/pvp.png',
    short: 'PvP'
  },
  {
    name: 'Miscellaneous',
    emoji: '/static/images/WoW/Emojis/miscellaneous.png',
    short: 'Misc'
  }
];

export const ActiveOfferTypes: OfferTypeNames[] = OfferTypes.map(
  (offerType) => offerType.name
);

export interface OfferNotificationSettings {
  newOffer: boolean;
  sound: boolean;
  soundVolume: number;
  windowsNotif: boolean;
  eligibleOnly: boolean;
  filteredOnly: boolean;
  friendOnly: boolean;
}

export interface inviteNotificationSettings {
  invite: boolean;
  sound: boolean;
  soundVolume: number;
  windowsNotif: boolean;
}

export interface GroupMember {
  _id?: number;
  Role: WoWRoleType;
  Class: string;
  IO?: number;
  RaidParse?: number;
  RaidProg?: number;
  Ilvl?: number;
  Key?: string;
  GoldTaken?: boolean;
  GroupLeader?: boolean;
  PaymentCharacter: Character;
}

export interface Offer {
  _id?: string;
  AcceptingBoosters?: boolean;
  AcceptingBuyers?: boolean;
  PosterID?: string;
  PosterDetails?: DiscordDetails;
  Realm?: string;
  Faction?: 'Horde' | 'Alliance';
  PaymentFactions?: ('Horde' | 'Alliance')[];
  Server?: 'US' | 'EU';
  Global?: boolean;
  CrossFaction?: boolean;
  Hidden?: boolean;
  PosterPoints?: number;
  MinimumKarma?: number;
  MinimumRating?: number;
  Note?: string;
  UploadTime?: number;
  StartTime?: number;
  EndTime?: number;
  TotalSpots?: Record<WoWRoleType, number>;
  CurrentGroup?: GroupMember[];
  Status?: 'Pending' | 'Ongoing' | 'Canceled' | 'Completed' | 'Failed' | 'Paid';
  OfferType?: OfferTypeNames;
  IncreasedCooldown?: boolean;
  AutoFill?: boolean;
  AutoFillFilters?: signupFilters;
  AutoRepost?: number;
  MutePremiumNotification?: boolean;
  Premium?: number;
  PosterReviews?: [number, number];
  SignedUpCharacters?: SignedupChar[];
  TotalSignUps?: number;
  LastRepost?: number;
  Karma?: number;
  BoosterKarma?: number;
  LeadIDs?: string[];
  LeadDetails?: { [key: string]: DiscordDetails };
}

export interface PartyOffer extends Offer {
  MinimumIlvl?: number;
  EligibleClasses?: string[];
  EligibleClassesByRole?: { [key: string]: string[] };
}

export interface PartyOfferSale extends PartyOffer {
  PaymentRealms?: string[];
  PosterCut?: number;
  TotalPayment?: number;
  TradePictures?: string[];
  BelowMarketAverage?: boolean;
  RoleBonusPayment?: { [key: string]: number };
  GroupLeaderBonus?: number;
}
export interface BuyerAcceptingOffer extends PartyOfferSale {
  Title: string;
  PriceList: PricelistItem[];
  PosterPaymentCharacter: Character;
  BNetRequired: boolean;
  SelfPay: boolean;
  Buyers: Record<string, Buyer>;
  Coordinator: string;
  CoordinatorBonusPercentage: number;
}

export interface MythicPlusOffer extends PartyOffer {
  Quantity?: number;
  Difficulty?: 'Normal' | 'Heroic' | 'Mythic';
  Level?: number;
  Timing?: 'Required' | 'Not Required';
  MinimumIO?: number;
  Keys?: string[][];
  RemainingKeys?: string[][];
  OfferType: 'MythicPlus' | 'MegaDungeon';
}

export interface MegaDungeonOffer extends MythicPlusOffer {
  HardMode?: boolean;
  Unsaved?: boolean;
  OfferType: 'MegaDungeon';
}

export interface LevelingOffer extends PartyOffer {
  StartLevel?: number;
  EndLevel?: number;
  OfferType: 'Leveling';
}

export interface RaidOffer extends PartyOffer {
  Bosses?: string[];
  Difficulty?: 'Normal' | 'Heroic' | 'Mythic';
  RaidLeader?: string;
  RaidCoordinator?: string;
  Unsaved?: boolean;
  ScheduledStartTime?: number;
  MinimumProgress?: number;
  MinimumParse?: number;
  MinimumMedianParse?: number;
  OfferType: 'Raid';
}

export interface MiscellaneousOffer extends PartyOffer {
  Title: string;
  OfferType: 'Miscellaneous';
}

export interface DelveOffer extends PartyOffer {
  Tier: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  Quantity: number;
  Bountiful: boolean;
  OfferType: 'Delve';
}

export interface PvPOffer extends PartyOffer {
  StartingRating: number;
  Mode: '2v2' | '3v3' | 'RBG';
  MinimumCurrentRating?: number;
  MinimumHistoricalRating?: number;
  OfferType: 'PvP';
}

interface FinishedMythicPlusRuns {
  _id: number;
  Season: string;
  Dungeon: string;
  Level: number;
  CompletionTimeStamp: number;
  Chests: number;
  Affixes: string[];
}

export interface MythicPlusSale extends MythicPlusOffer, PartyOfferSale {
  KeyBonusPayment?: number;
  BuyerKey?: string;
  BuyerParticipation?: boolean;
  FinishedRuns?: FinishedMythicPlusRuns[];
  OfferType: 'MythicPlus' | 'MegaDungeon';
}

export interface MegaDungeonSale extends MythicPlusSale, PartyOfferSale {
  BuyerKey: 'Dawn of the Infinite';
  HardMode?: boolean;
  Unsaved?: boolean;
  OfferType: 'MegaDungeon';
}

export interface LevelingSale extends LevelingOffer, PartyOfferSale {
  OfferType: 'Leveling';
}

export interface RaidSale extends RaidOffer, PartyOfferSale {
  OfferType: 'Raid';
}

export interface MiscellaneousSale extends MiscellaneousOffer, PartyOfferSale {
  OfferType: 'Miscellaneous';
}

export interface DelveSale extends DelveOffer, PartyOfferSale {
  OfferType: 'Delve';
}

export interface PvPSale extends PvPOffer, PartyOfferSale {
  OfferType: 'PvP';
  BoostMode: 'PerMatch' | 'Hourly' | 'Rating Goal' | 'Coaching';
  GoalRating?: number;
}

export interface MythicPlusLFS extends MythicPlusSale, BuyerAcceptingOffer {
  OfferType: 'MythicPlus';
}

export interface MegaDungeonLFS extends MegaDungeonSale, BuyerAcceptingOffer {
  OfferType: 'MegaDungeon';
}

export interface LevelingLFS extends LevelingSale, BuyerAcceptingOffer {
  OfferType: 'Leveling';
}

export interface RaidLFS extends RaidSale, BuyerAcceptingOffer {
  OfferType: 'Raid';
}

export interface MiscellaneousLFS
  extends MiscellaneousSale,
    BuyerAcceptingOffer {
  OfferType: 'Miscellaneous';
}

export interface DelveLFS extends DelveSale, BuyerAcceptingOffer {
  OfferType: 'Delve';
}

export interface PvPLFS extends PvPSale, BuyerAcceptingOffer {
  OfferType: 'PvP';
}

export type LFGType =
  | Offer
  | PartyOffer
  | MythicPlusOffer
  | MegaDungeonOffer
  | LevelingOffer
  | RaidOffer
  | MiscellaneousOffer
  | PvPOffer
  | DelveOffer;

export type LFBType =
  | PartyOfferSale
  | MythicPlusSale
  | MegaDungeonSale
  | LevelingSale
  | RaidSale
  | MiscellaneousSale
  | PvPSale
  | DelveSale;

export type LFSType =
  | BuyerAcceptingOffer
  | MythicPlusLFS
  | MegaDungeonLFS
  | LevelingLFS
  | RaidLFS
  | MiscellaneousLFS
  | PvPLFS
  | DelveLFS;

export type offerObjectType = LFGType | LFSType | LFSType;

export const OfferExpiryTimes = {
  MythicPlus: [3600, 86400],
  MegaDungeon: [7200, 86400],
  Leveling: [7200, 172800],
  Raid: [7200, 172800],
  Miscellaneous: [7200, 172800],
  PvP: [7200, 86400],
  Delve: [3600, 86400]
};

export function isFullOffer(offer: offerObjectType, isLFS: boolean): boolean {
  const IsBoosterSpotsFull =
    Object.values(offer.TotalSpots).reduce((a, b) => a + b, 0) <=
    offer.CurrentGroup.length;
  const IsBuyerSpotsFull =
    'Buyers' in offer
      ? offer.PriceList?.every(
          (PriceItem) =>
            PriceItem.MaximumBuyers > 0 &&
            Object.values(offer.Buyers).reduce(
              (count, Buyer) =>
                count +
                Buyer.Characters.filter((Character) =>
                  Character.Items.some((Item) => Item._id === PriceItem._id)
                ).length,
              0
            ) >= PriceItem.MaximumBuyers
        )
      : true;
  return !isLFS ? IsBoosterSpotsFull : IsBuyerSpotsFull;
}

export function getActiveOffers(): offerObjectType[] {
  const activeOffers = useSelector(
    (state: RootState) => state.offer.activeOffers
  );
  if (activeOffers) {
    return Object.values(activeOffers).filter((offer) => offer !== undefined);
  }
  return [];
}

export function findOfferById(id: string): Offer | undefined {
  const activeOffers = getActiveOffers();
  return activeOffers.find((offer) => offer._id === id);
}

export function CreditibilityIndex(Points: number): 0 | 1 | 2 {
  if (Points !== undefined && Points < 50) {
    return 0;
  } else if (Points < 500) {
    return 1;
  }
  return 2;
}

export function getRecentRuns(): { [key: string]: offerObjectType } {
  const recentRuns = getUser().RecentRuns;
  return recentRuns;
}

export function offerDetails(offer: offerObjectType): string {
  let details = '';
  if (offer?.AcceptingBuyers && 'Title' in offer) return offer.Title;
  if (offer?.OfferType === 'MythicPlus') {
    if ('Quantity' in offer) details += `${offer.Quantity}x`;
    if ('Difficulty' in offer) {
      if (offer.Difficulty === 'Mythic') {
        details += ' M';
        if ('Level' in offer) details += `+${offer.Level}`;
        if ('Timing' in offer)
          details += offer.Timing === 'Required' ? ' Timed' : ' Untimed';
      } else details += ` ${offer.Difficulty} Dungeon`;
    }
  } else if (offer?.OfferType === 'MegaDungeon') {
    details += 'DoI';
    if ('Unsaved' in offer) details += offer.Unsaved ? ' Unsaved' : ' Saved';
    if ('HardMode' in offer && offer.HardMode) details += ' Hardmode';
  } else if (offer?.OfferType === 'Leveling') {
    details += 'Leveling';
    if ('StartLevel' in offer) details += ` ${offer.StartLevel} to `;
    if ('EndLevel' in offer) details += `${offer.EndLevel}`;
  } else if (offer?.OfferType === 'Raid') {
    if ('Difficulty' in offer) details += ` ${offer.Difficulty}`;
    if ('Bosses' in offer && offer.Bosses.length === 1) {
      const bossItem = RaidBosses.find((boss) => boss.Name === offer.Bosses[0]);
      details += ` ${bossItem.Short}`;
    } else if ('Bosses' in offer && offer.Bosses.includes('Full Clear')) {
      details += ` Full Clear`;
    } else details += ' Raid';
    if ('ScheduledStartTime' in offer) {
      const date = new Date(offer.ScheduledStartTime * 1000);
      // Only show the month and day and hour and pm/am
      details += ` ${date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        hour12: true,
        minute: 'numeric'
      })}`;
    }
  } else if (offer?.OfferType === 'PvP') {
    if ('Mode' in offer) details += `${offer.Mode}`;
    details += ' PvP';
    if ('StartingRating' in offer && offer.StartingRating)
      details += ` ${offer.StartingRating}CR`;
    if ('GoalRating' in offer && offer.GoalRating)
      details += ` to ${offer.GoalRating}CR`;
  } else if (offer?.OfferType === 'Miscellaneous') {
    if ('Title' in offer) details += ` ${offer.Title}`;
  } else if (offer?.OfferType === 'Delve') {
    if ('Quantity' in offer) details += `${offer.Quantity}x`;
    details += ` Delve`;
    if ('Tier' in offer) details += ` T${offer.Tier}`;
    if ('Bountiful' in offer && offer.Bountiful) details += ' Bountiful';
  }
  return details;
}

export function MarketAverageIndex(
  offer: PartyOfferSale,
  stats: averageStats
): 0 | 1 | 2 {
  const marketAveragePrice = Math.floor(
    findRelevantValue('TotalPayment', offer, stats) / 1.2
  );
  if (
    offer !== undefined && 'BelowMarketAverage' in offer
      ? offer?.BelowMarketAverage
      : offer?.TotalPayment < marketAveragePrice
  ) {
    return 0;
  } else if (offer?.TotalPayment === marketAveragePrice) {
    return 1;
  }
  return 2;
}

export class OfferObj {
  offer: offerObjectType;

  constructor(offer: Offer) {
    this.offer = offer;
  }

  Details(): string {
    return offerDetails(this.offer);
  }

  Description(): string {
    let description = `${this.offer?.OfferType} `;
    description += this.Details();
    return description;
  }

  Oneliner(): string {
    let oneliner = this.Description();
    if (this.offer && 'TotalPayment' in this.offer)
      oneliner += ` ${this.offer?.TotalPayment}K`;
    return `${oneliner} ${this.offer?.Realm}`;
  }

  CreditibilityIndex(): 0 | 1 | 2 {
    return CreditibilityIndex(this.offer?.PosterPoints);
  }

  ReviewsString(): string {
    if (this.offer && 'PosterReviews' in this.offer)
      return getReviewsString(this.offer?.PosterReviews);
    return 'No Reviews';
  }

  isArmorStack(): boolean {
    if (this.offer && 'EligibleClasses' in this.offer) {
      return this.offer?.EligibleClasses?.length !== Classes.length;
    }
    return false;
  }

  AllKeys(): string[] {
    if (this.offer && 'Keys' in this.offer) {
      let keys: string[] = [];
      this.offer?.Keys?.forEach((keyList) => {
        keyList?.forEach((key) => {
          if (!keys.includes(key)) keys.push(key);
        });
      });
      return keys;
    }
    return [];
  }

  CurrentKeys(): string[] {
    if (this.offer && 'CurrentGroup' in this.offer) {
      let keys: string[] = [];
      this.offer?.CurrentGroup?.forEach((member) => {
        if (member?.Key && !keys?.includes(member?.Key)) keys.push(member?.Key);
      });
      return keys;
    }
    return [];
  }

  RemainingKeys(): string[] {
    if (this.offer && 'RemainingKeys' in this.offer) {
      let keys: string[] = [];
      this.offer?.RemainingKeys?.forEach((keyList) => {
        keyList?.forEach((key) => {
          if (!keys.includes(key)) keys?.push(key);
        });
      });
      return keys;
    }
    return [];
  }

  OpenSpots(): Record<WoWRoleType, number> {
    let openSpots: Record<WoWRoleType, number> = { ...this.offer?.TotalSpots };
    if (this.offer && 'CurrentGroup' in this.offer) {
      this.offer?.CurrentGroup?.forEach((member) => {
        if (member?.Role in openSpots && openSpots[member?.Role] > 0)
          openSpots[member?.Role]--;
      });
    }
    return Object.fromEntries(
      Object.entries(openSpots).filter(([role, spots]) => spots > 0)
    ) as Record<WoWRoleType, number>;
  }

  TotalBooster(): number {
    let totalBooster = 0;
    if (this.offer && 'TotalSpots' in this.offer) {
      for (const role in this.offer?.TotalSpots) {
        totalBooster += this.offer?.TotalSpots?.[role];
      }
    }
    return totalBooster;
  }

  TotalPaymentIncludingBuyers(): number {
    let TotalPayment = 0;
    if (this.offer && 'TotalPayment' in this.offer) {
      TotalPayment = this.offer?.TotalPayment;
    }
    if (this.offer && 'Buyers' in this.offer) {
      for (const key in this.offer?.Buyers) {
        for (const character of this.offer?.Buyers?.[key]?.Characters) {
          TotalPayment += character.TotalPayment;
        }
      }
    }
    if (this.offer && 'GroupLeaderBonus' in this.offer) {
      TotalPayment -= this.offer?.GroupLeaderBonus;
    }
    return TotalPayment;
  }

  CoordinatorCut(): number {
    const TotalPayment = this.TotalPaymentIncludingBuyers();
    if (this.offer && 'CoordinatorBonusPercentage' in this.offer) {
      return TotalPayment * (this.offer?.CoordinatorBonusPercentage / 100);
    }
    return 0;
  }

  TotalPaymentIncludingBuyersExcludingCoordinator(): number {
    return this.TotalPaymentIncludingBuyers() - this.CoordinatorCut();
  }

  CutPerBooster(): number {
    const TotalBoosters = this.TotalBooster();
    if (TotalBoosters !== undefined && TotalBoosters > 0)
      return (
        this.TotalPaymentIncludingBuyersExcludingCoordinator() /
        this.TotalBooster()
      );
    return this.TotalPaymentIncludingBuyersExcludingCoordinator();
  }

  CutPerBoosterString(): string {
    const Cut = this.CutPerBooster();
    return this.offer &&
      'Quantity' in this.offer &&
      this.offer?.Quantity !== undefined &&
      this.offer?.Quantity > 1
      ? `${Cut.toFixed(1)}K (${(Cut / this.offer?.Quantity).toFixed(1)}K/run)`
      : `${Cut.toFixed(1)}K`;
  }

  CoordinatorCutString(): string {
    if (this.offer && 'CoordinatorBonusPercentage' in this.offer) {
      const Cut = this.CoordinatorCut();
      return Cut
        ? `${Cut.toFixed(1)}K (${this.offer?.CoordinatorBonusPercentage}%)`
        : '';
    }
    return '';
  }

  RoleBonusPayment(): { [key: string]: number } {
    let RoleBonusPayment: { [key: string]: number } = {};
    if (this.offer && 'RoleBonusPayment' in this.offer)
      for (const key in this.offer?.RoleBonusPayment) {
        if (this.offer?.TotalSpots?.[key] > 0)
          RoleBonusPayment[key] = this.offer?.RoleBonusPayment?.[key];
      }
    return RoleBonusPayment;
  }

  // ArmorsCount(): Record<WoWArmorType, number> {
  //   let ArmorsCount: Record<WoWArmorType, number> = {
  //     Cloth: 0,
  //     Leather: 0,
  //     Mail: 0,
  //     Plate: 0
  //   };
  //   if (this.offer && 'CurrentGroup' in this.offer)
  //     this.offer?.CurrentGroup?.forEach((member) => {
  //       ArmorsCount[Classes.find((c) => c.Name === member.Class)?.Armor]++;
  //     });
  //   return ArmorsCount;
  // }

  // TokensCount(): Record<WoWTierToken, number> {
  //   let TokensCount: Record<WoWTierToken, number> = {
  //     Dreadful: 0,
  //     Mystic: 0,
  //     Venerated: 0,
  //     Zenith: 0
  //   };
  //   if (this.offer && 'CurrentGroup' in this.offer)
  //     this.offer?.CurrentGroup?.forEach((member) => {
  //       TokensCount[Classes.find((c) => c.Name === member.Class)?.Tier]++;
  //     });
  //   return TokensCount;
  // }

  // TakenArmorSpots(): Record<WoWArmorType, number> {
  //   let TakenArmorSpots: Record<WoWArmorType, number> = {
  //     Cloth: 0,
  //     Leather: 0,
  //     Mail: 0,
  //     Plate: 0
  //   };
  //   if (this.offer && 'Buyers' in this.offer)
  //     Object.values(this.offer?.Buyers).forEach((buyer) => {
  //       buyer.Characters.forEach((character) => {
  //         TakenArmorSpots[
  //           Classes.find((c) => c.Name === character.Class)?.Armor
  //         ] += character.ArmorTraders;
  //       });
  //     });
  //   return TakenArmorSpots;
  // }

  // TakenTokensSpots(): Record<WoWTierToken, number> {
  //   let TakenTokensSpots: Record<WoWTierToken, number> = {
  //     Dreadful: 0,
  //     Mystic: 0,
  //     Venerated: 0,
  //     Zenith: 0
  //   };
  //   if (this.offer && 'Buyers' in this.offer)
  //     Object.values(this.offer?.Buyers).forEach((buyer) => {
  //       buyer.Characters.forEach((character) => {
  //         TakenTokensSpots[
  //           Classes.find((c) => c.Name === character.Class)?.Tier
  //         ] += character.TokenTraders;
  //       });
  //     });
  //   return TakenTokensSpots;
  // }

  // OpenArmorSpots(): Record<WoWArmorType, number> {
  //   if (this.offer && 'ArmorTraders' in this.offer) {
  //     const ArmorsCount = this.offer.ArmorTraders;
  //     const TakenArmorSpots = this.TakenArmorSpots();
  //     return Object.fromEntries(
  //       Object.entries(ArmorsCount).map(([key, value]) => [
  //         key,
  //         value - (TakenArmorSpots[key] ?? 0)
  //       ])
  //     ) as Record<WoWArmorType, number>;
  //   }
  //   return undefined;
  // }

  // OpenTokenSpots(): Record<WoWTierToken, number> {
  //   if (this.offer && 'TokenTraders' in this.offer) {
  //     const TokensCount = this.offer.TokenTraders;
  //     const TakenTokensSpots = this.TakenTokensSpots();
  //     return Object.fromEntries(
  //       Object.entries(TokensCount).map(([key, value]) => [
  //         key,
  //         value - (TakenTokensSpots[key] ?? 0)
  //       ])
  //     ) as Record<WoWTierToken, number>;
  //   }
  //   return undefined;
  // }
}

export class SaleOfferObj extends OfferObj {
  offer: LFBType | LFSType;

  constructor(offer: LFBType | LFSType) {
    super(offer);
    this.offer = offer;
  }

  MarketAverageIndex(stats: averageStats): 0 | 1 | 2 {
    return MarketAverageIndex(this.offer, stats);
  }
}

export function checkRequirments(
  offer: LFBType | LFSType,
  requirements: MinimumOfferRequirements
): boolean {
  const OfferObj = new SaleOfferObj(offer);
  if (!requirements.OfferTypes.includes(offer.OfferType)) {
    return false;
  }
  if (
    (requirements.KarmaGain > 0 && offer.BoosterKarma < 0) ||
    (requirements.KarmaGain === 2 && offer.BoosterKarma === 0)
  ) {
    return false;
  }
  if (
    requirements.MinPosterKarma !== null &&
    requirements.MinPosterKarma > offer.PosterDetails?.['Karma']
  ) {
    return false;
  }
  if (offer.IncreasedCooldown && requirements.NoExtremelyLowCut) {
    return false;
  }
  if ('Level' in offer) {
    if (offer.Level < requirements.MinimumKeyLevel) {
      return false;
    }
    if (offer.Level > requirements.MaximumKeyLevel) {
      return false;
    }
    if (offer.Quantity < requirements.MinimumKeysQuantity) {
      return false;
    }
    if (offer.Quantity > requirements.MaximumKeysQuantity) {
      return false;
    }
    if (offer.Timing === 'Required' && !requirements.TimingStatus) {
      return false;
    }
    // if (offer.Timing === 'Not Required' && requirements.TimingStatus) {
    //   return false;
    // }
  }
  if (
    'MinimumIO' in offer &&
    requirements.MinimumIO &&
    offer.MinimumIO < requirements.MinimumIO
  ) {
    return false;
  }
  if (!requirements.LFGOrLFB.includes('LFB') && 'TotalPayment' in offer) {
    return false;
  }
  if (!requirements.LFGOrLFB.includes('LFG') && !('TotalPayment' in offer)) {
    return false;
  }
  if (requirements.MarketAverage && offer.BelowMarketAverage) {
    return false;
  }
  if (!requirements.AcceptArmorStack && OfferObj.isArmorStack()) {
    return false;
  }
  if (
    'Quantity' in offer &&
    requirements.CutPerKey > OfferObj.CutPerBooster() / offer.Quantity
  ) {
    return false;
  }
  if (
    'BuyerParticipation' in offer &&
    requirements.BuyerParticipation &&
    !offer.BuyerParticipation
  ) {
    return false;
  }
  if (requirements.PosterTrustLevel > OfferObj.CreditibilityIndex()) {
    return false;
  }
  return true;
}

export function isCharacterEligible(
  character: MythicPlusCharacter,
  offer: offerObjectType
) {
  const OfferObj = new SaleOfferObj(offer);
  if (!offer?.CrossFaction && offer?.Faction !== character?.Faction) {
    return [false, `Offer does not accept ${character?.Faction} characters`];
  }
  if (
    'EligibleClasses' in offer &&
    offer?.EligibleClasses?.length > 0 &&
    !offer?.EligibleClasses?.includes(character?.Class)
  ) {
    return [false, `Offer does not accept ${character?.Class}`];
  }
  if (
    'MinimumIlvl' in offer &&
    offer?.MinimumIlvl &&
    offer?.MinimumIlvl > character?.MinimumIlvl
  ) {
    return [
      false,
      `Character's minimum ilvl is ${character?.MinimumIlvl} but offer requires ${offer?.MinimumIlvl}`
    ];
  }
  if (
    'MinimumIO' in offer &&
    offer?.MinimumIO &&
    !Object.keys(OfferObj.OpenSpots()).some(
      (role) =>
        character?.RoleIOs?.[role] > offer?.MinimumIO &&
        offer.EligibleClassesByRole[role]?.includes(character?.Class)
    )
  ) {
    const CharacterIOs = Object.keys(OfferObj.OpenSpots())
      .filter((role) =>
        offer.EligibleClassesByRole[role]?.includes(character?.Class)
      )
      .map((role) => `${role}: ${character?.RoleIOs?.[role] ?? 0}`);
    if (CharacterIOs.length === 0) {
      return [
        false,
        `You didn't select any roles that the offer is looking for`
      ];
    }
    return [
      false,
      `Character's IOs are ${CharacterIOs.join(', ')} but offer requires ${
        offer?.MinimumIO
      } IO`
    ];
  }
  return [true, `Character is eligible`];
}

export function isUserEligible(user: User, offer: offerObjectType) {
  if (offer.MinimumKarma && user.Karma < offer.MinimumKarma) {
    return false;
  }
  if (
    ![...user?.PaymentCharacters, ...user?.Characters]?.some(
      (character) =>
        character.Payment &&
        character.Realm === offer.Realm &&
        offer.PaymentFactions?.includes(character.Faction)
    )
  ) {
    return false;
  }
  return user?.AutoSignupCharacters?.some(
    (character) => isCharacterEligible(character, offer)[0]
  );
}

export function IneligibleReason(
  user: User,
  offer: offerObjectType
): [MythicPlusCharacter, string][] {
  if (offer.MinimumKarma && user.Karma < offer.MinimumKarma)
    return [
      [
        null,
        `Your karma is ${user.Karma} but offer requires ${offer.MinimumKarma}`
      ]
    ];
  if (
    ![...user?.PaymentCharacters, ...user?.Characters]?.some(
      (character) =>
        character.Payment &&
        character.Realm === offer.Realm &&
        offer.PaymentFactions?.includes(character.Faction)
    )
  ) {
    return [
      [
        null,
        `You do not have any characters or payment characters in ${
          offer.Realm
        } on ${offer.PaymentFactions?.join(' or ')}`
      ]
    ];
  }
  return (
    user?.AutoSignupCharacters?.map(
      (character) =>
        [character, isCharacterEligible(character, offer)[1]] as [
          MythicPlusCharacter,
          string
        ]
    ) ?? []
  );
}

export function filterEligibleOffers(user: User, offers: offerObjectType[]) {
  if (!user) return offers;
  return [...offers.filter((offer) => isUserEligible(user, offer))];
}

export function filterOffers(
  offers: offerObjectType[],
  requirements: MinimumOfferRequirements,
  user?: User
) {
  const filteredOffers = [
    ...offers.filter((offer) => checkRequirments(offer, requirements))
  ];
  return requirements.eligibleOnly && user
    ? filterEligibleOffers(user, filteredOffers)
    : filteredOffers;
}

export type SortCriteria =
  | 'Cut Per Run'
  | 'Total Cut'
  | 'Level'
  | 'Poster Karma'
  | 'Karma Gain';

interface SortConfig {
  criteria: SortCriteria;
  customFunc?: (obj: SaleOfferObj) => number;
}

export function sortOffers(offers: offerObjectType[], key?: SortCriteria) {
  const config = key
    ? defaultOfferFilterConfigs[key]
    : defaultOfferFilterConfigs['Poster Karma'];
  return [
    ...offers.sort((a, b) => {
      const RepostScore = Math.sign((b.LastRepost || 0) - (a.LastRepost || 0));
      // if (!key) return RepostScore;
      if (config.customFunc) {
        const OfferObjA = new SaleOfferObj(a);
        const OfferObjB = new SaleOfferObj(b);
        return (
          Math.sign(
            config.customFunc(OfferObjB) - config.customFunc(OfferObjA)
          ) *
            2 +
          RepostScore
        );
      }
      return (
        Math.sign(b[config.criteria] - a[config.criteria]) * 2 + RepostScore
      );
    })
  ];
}

export const defaultOfferFilterConfigs: { [key in SortCriteria]: SortConfig } =
  {
    'Cut Per Run': {
      criteria: 'Cut Per Run',
      customFunc: (obj: SaleOfferObj) =>
        obj.CutPerBooster() / ('Quantity' in obj.offer ? obj.offer.Quantity : 1)
    },
    'Total Cut': {
      criteria: 'Total Cut',
      customFunc: (obj: SaleOfferObj) => obj.CutPerBooster()
    },
    Level: {
      criteria: 'Level'
    },
    'Poster Karma': {
      criteria: 'Poster Karma',
      customFunc: (obj: SaleOfferObj) => obj.offer.PosterDetails?.Karma
    },
    'Karma Gain': {
      criteria: 'Karma Gain',
      customFunc: (obj: SaleOfferObj) => obj.offer.BoosterKarma
    }
  };

export interface LFSFiltersType {
  Categories?: OfferTypeNames[];
  SubCategories?: string[];
  Tags?: string[];
  MinPrice?: number;
  MaxPrice?: number;
  StartTypes?: ('Instant' | 'Scheduled' | 'OnFill' | 'OnAgreement')[];
  MinStart?: Date;
  MaxStart?: Date;
}

export function filterLFSOffers(
  offers: LFSType[],
  filters: LFSFiltersType,
  user?: User
): LFSType[] {
  return [
    ...offers.filter((offer) =>
      offer.PriceList?.some((item) => {
        const matchesCategory =
          !filters.Categories ||
          filters.Categories.length === 0 ||
          filters.Categories.includes(item.Category);

        const matchesSubCategory =
          !filters.SubCategories ||
          filters.SubCategories.length === 0 ||
          filters.SubCategories.includes(item.SubCategory);

        const matchesTags =
          !filters.Tags ||
          filters.Tags.length === 0 ||
          filters.Tags.some((tag) => item.Tags.includes(tag));

        const matchesPrice =
          (!filters.MinPrice || item.BasePrice >= filters.MinPrice) &&
          (!filters.MaxPrice || item.BasePrice <= filters.MaxPrice);

        const matchesStartType =
          !filters.StartTypes ||
          filters.StartTypes.length === 0 ||
          filters.StartTypes.includes(item.StartType);

        const matchesSchedule =
          !filters.StartTypes ||
          !filters.StartTypes.includes('Scheduled') ||
          item.StartType === 'Instant' ||
          item.StartType === 'OnFill' ||
          item.StartType === 'OnAgreement' ||
          (item.ScheduleType === 'Once' &&
            (!filters.MinStart ||
              item.ScheduleDate >= filters.MinStart.getTime()) &&
            (!filters.MaxStart ||
              item.ScheduleDate <= filters.MaxStart.getTime())) ||
          (item.ScheduleType === 'Recurring' &&
          filters.MinStart &&
          filters.MaxStart &&
          filters.MaxStart.getTime() - filters.MinStart.getTime() >=
            7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
            ? true // Automatically pass if the time span is a week or more
            : item.ScheduleTimeSlots?.some(([day, hour, minute]) => {
                const minStart = filters.MinStart
                  ? [
                      filters.MinStart.getDay(),
                      filters.MinStart.getHours(),
                      filters.MinStart.getMinutes()
                    ]
                  : [0, 0, 0];

                const maxStart = filters.MaxStart
                  ? [
                      filters.MaxStart.getDay(),
                      filters.MaxStart.getHours(),
                      filters.MaxStart.getMinutes()
                    ]
                  : [7, 24, 0];

                return (
                  (day > minStart[0] ||
                    (day === minStart[0] && hour > minStart[1]) ||
                    (day === minStart[0] &&
                      hour === minStart[1] &&
                      minute >= minStart[2])) &&
                  (day < maxStart[0] ||
                    (day === maxStart[0] && hour < maxStart[1]) ||
                    (day === maxStart[0] &&
                      hour === maxStart[1] &&
                      minute <= maxStart[2]))
                );
              }));

        return (
          matchesCategory &&
          matchesSubCategory &&
          matchesTags &&
          matchesPrice &&
          matchesStartType &&
          matchesSchedule
        );
      })
    )
  ];
}

export function MakeMockOffer(user: User): MythicPlusSale {
  const Offer: MythicPlusSale = {
    _id: 'MockOffer',
    AcceptingBoosters: true,
    AcceptingBuyers: false,
    PosterID: user._id,
    PosterDetails: user.DiscordDetails,
    Realm: 'Tichondrius',
    Faction: 'Horde',
    PaymentFactions: ['Horde'],
    Server: 'US',
    Global: false,
    CrossFaction: false,
    Hidden: false,
    PosterPoints: 100,
    MinimumKarma: 0,
    MinimumRating: 0,
    Note: user.DefaultPosterNote,
    UploadTime: Date.now(),
    StartTime: Date.now(),
    EndTime: Date.now() + 86400000,
    TotalSpots: {
      Tank: 1,
      Healer: 1,
      DPS: 3
    },
    CurrentGroup: [],
    Status: 'Pending',
    OfferType: 'MythicPlus',
    IncreasedCooldown: false,
    AutoFill: false,
    AutoRepost: 0,
    MutePremiumNotification: false,
    Premium: 0,
    PosterReviews: [0, 0],
    SignedUpCharacters: [],
    TotalSignUps: 0,
    LastRepost: Date.now(),
    Karma: 0,
    BoosterKarma: 0,
    LeadIDs: []
  };
  return Offer;
}
