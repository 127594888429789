import React, { useEffect } from 'react';
import ChatBox from './ChatBox';
import { Box } from '@mui/material';
import { getRecentMessages } from 'src/models/message';
import { RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeOffset } from 'src/models/client';

const ChatBoxes: React.FC = () => {
  const dispatch = useDispatch();
  const allMessages = useSelector(
    (state: RootState) => state?.user?.user?.Messages
  );
  const userID = useSelector((state: RootState) => state?.user?.user?._id);
  const CachedDiscordDetails = useSelector(
    (state: RootState) => state?.user?.user?.CachedDiscordDetails
  );

  const recentMessages = getRecentMessages(allMessages, [userID]);
  const maintainedRooms = useSelector(
    (state: RootState) => state?.user?.user?.MaintainedChatRooms
  );
  const mutedRooms =
    useSelector(
      (state: RootState) => state?.notifications?.messages?.MutedIDs
    ) ?? [];
  const nonMutedRooms = maintainedRooms?.filter(
    (roomID) =>
      !mutedRooms?.includes(roomID) && (recentMessages?.[roomID] ?? [])?.length
  );
  const timeOffset = getTimeOffset();

  useEffect(() => {
    const now = Date.now() - timeOffset;

    const isDiscordID = (id) => /^\d{16,20}$/.test(id);

    const allDiscordDetails = maintainedRooms.filter((roomID) =>
      isDiscordID(roomID)
    );

    const missingDiscordDetails = allDiscordDetails.filter(
      (topUser) =>
        (topUser && !CachedDiscordDetails?.[topUser]) ||
        CachedDiscordDetails?.[topUser].LastUpdate < now / 1000 - 86400
    );

    if (missingDiscordDetails.length > 0) {
      dispatch({
        type: 'socket/Message/send',
        payload: {
          type: 'get_bulk_discord_details',
          data: {
            DiscordIDs: missingDiscordDetails.map((item) => item)
          },
          silent: true
        }
      });
    }
  }, [CachedDiscordDetails, maintainedRooms]);

  return (
    <Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 10,
          right: 10,
          display: 'flex',
          flexDirection: 'row-reverse', // Align chat boxes to the right
          alignItems: 'flex-end',
          pointerEvents: 'none', // Prevent the entire container from blocking clicks
          zIndex: 2000
        }}
      >
        {nonMutedRooms?.map((roomID, index) => (
          <Box
            key={roomID}
            sx={{
              position: 'relative',
              pointerEvents: 'auto', // Allow interactions with the chat boxes
              marginRight: index > 0 ? 2 : 0
            }}
          >
            <ChatBox
              messages={recentMessages[roomID]}
              userID={userID}
              roomID={roomID}
              CachedDiscordDetails={CachedDiscordDetails}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ChatBoxes;
