import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import {
  Leaderboard,
  LeaderboardItem,
  Leaderboards,
  UserRank,
  UserRanks
} from 'src/models/leaderboard';

const migrations = {
  1: (state) => {
    const migratedState = { ...state };
    if (!migratedState?.karma)
      migratedState.karma = {
        leaderboard: {},
        userRank: {
          _id: '',
          Runs: 0,
          Rank: 0
        },
        lastUpdate: 0
      };
    return migratedState;
  },
  2: (state) => {
    const migratedState = { ...state };
    if (
      !migratedState?.karma ||
      !Array.isArray(migratedState.karma.leaderboard)
    )
      migratedState.karma = {
        leaderboard: [],
        userRank: {
          _id: '',
          Runs: 0,
          Rank: 0
        },
        lastUpdate: 0
      };
    return migratedState;
  },
  3: (state) => {
    const migratedState = { ...state };
    if (!migratedState?.personal)
      migratedState.personal = {
        current: {},
        previous: {},
        lastUpdate: 0
      };
    return migratedState;
  }
};

const persistConfig = {
  key: 'leaderboards',
  storage: storage,
  version: 3,
  migrate: createMigrate(migrations)
};

interface leaderboardsProps {
  current: {
    leaderboards: Leaderboards;
    userRanks: UserRanks;
    lastUpdate: number;
  };
  previous: {
    leaderboards: Leaderboards;
    userRanks: UserRanks;
    lastUpdate: number;
  };
  karma: {
    leaderboard: LeaderboardItem[];
    userRank: LeaderboardItem;
    lastUpdate: number;
  };
  personal: {
    current: Leaderboards;
    previous: Leaderboards;
    lastUpdate: number;
  };
}

const initialState: leaderboardsProps = {
  current: {
    leaderboards: {},
    userRanks: {},
    lastUpdate: 0
  },
  previous: {
    leaderboards: {},
    userRanks: {},
    lastUpdate: 0
  },
  karma: {
    leaderboard: [],
    userRank: {
      _id: '',
      Runs: 0,
      Rank: 0
    },
    lastUpdate: 0
  },
  personal: {
    current: {},
    previous: {},
    lastUpdate: 0
  }
};

export const leaderboardsSlice = createSlice({
  name: 'leaderboards',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (
      state,
      action: PayloadAction<{ data: leaderboardsProps; timeOffset: number }>
    ) => {
      for (const key in action.payload.data) {
        if (Object.prototype.hasOwnProperty.call(action.payload.data, key)) {
          state[key] = action.payload.data[key];
          state[key].lastUpdate = Date.now() - action.payload.timeOffset;
        }
      }
    },
    updateLeaderboards: (
      state,
      action: PayloadAction<{
        data: {
          type: 'current' | 'previous' | 'karma';
          leaderboards: Leaderboards | LeaderboardItem[];
        };
        timeOffset: number;
      }>
    ) => {
      if (action.payload.data.type === 'karma') {
        state[action.payload.data.type].leaderboard = action.payload.data
          .leaderboards as LeaderboardItem[];
        state[action.payload.data.type].lastUpdate =
          Date.now() - action.payload.timeOffset;
      } else {
        state[action.payload.data.type].leaderboards = action.payload.data
          .leaderboards as Leaderboards;
        state[action.payload.data.type].lastUpdate =
          Date.now() - action.payload.timeOffset;
      }
    },
    updateUserRanks: (
      state,
      action: PayloadAction<{
        data: {
          type: 'current' | 'previous' | 'karma';
          userRanks: UserRanks | LeaderboardItem;
        };
        timeOffset: number;
      }>
    ) => {
      if (action.payload.data.type === 'karma') {
        state[action.payload.data.type].userRank = action.payload.data
          .userRanks as LeaderboardItem;
        state[action.payload.data.type].lastUpdate =
          Date.now() - action.payload.timeOffset;
      } else {
        state[action.payload.data.type].userRanks = action.payload.data
          .userRanks as UserRanks;
        state[action.payload.data.type].lastUpdate =
          Date.now() - action.payload.timeOffset;
      }
    }
  }
});

export const { reset, update } = leaderboardsSlice.actions;
const persistedReducer = persistReducer(
  persistConfig,
  leaderboardsSlice.reducer
);
export default persistedReducer;
