import { RootState } from 'src/app/store';
import { Character, MakeMockCharacter, SignedUpCharacter } from './character';
import {
  Classes as WoWClasses,
  WoWClassType,
  WoWRoles,
  WoWRoleType
} from './wowclasses';
import {
  LevelingOffer,
  LevelingSale,
  LFSType,
  LFGType,
  MegaDungeonOffer,
  MegaDungeonSale,
  MythicPlusOffer,
  MythicPlusSale,
  OfferObj,
  offerObjectType,
  PartyOffer,
  PartyOfferSale
} from './offer';
import { DiscordDetails, User } from './user';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { WoWBuffs, WoWBuffsType } from './wowbuffs';
import { WoWArmorType } from './wowarmors';

export interface SignUpNotificationSettings {
  enable: boolean;
  sound: boolean;
  soundVolume: number;
  windowsNotif: boolean;
  filteredOnly: boolean;
  friendsOnly: boolean;
  keyholderOnly: boolean;
}

export interface SignedupChar {
  _id: number;
  Roles: WoWRoleType[];
}

export interface SignUp {
  BoosterID: string;
  BoosterDetails?: DiscordDetails;
  TimeStamp: number;
  BoostsCompleted: number;
  Reviews: [number, number];
  BNet: string;
  Roles: string[];
  SortFactors: { [key: string]: number };
  RealmPaymentCharacters: Character[];
  AcceptedCharacter: SignedUpCharacter;
  ChosenPaymentCharacter: Character;
  AcceptedRole: string;
  StatusReport: boolean;
  ScamReport: boolean;
  Note: string;
  Premium: number;
  BonusTip: number;
  GoldTaken: boolean;
  GroupLeader: boolean;
  Type: string;
  InviteResponse?: string;
  _id: string;
  Hide?: boolean;
}

export interface BRSignUp extends SignUp {
  Price?: number;
  RunTime?: number;
  Type: 'BRSignUp';
}

export interface PartySignUp extends SignUp {
  MainIO: number;
  Keys: string[];
  EligibleCharacters: SignedUpCharacter[];
  AcceptedKey: string;
  Type: 'Party';
}

export interface GroupSignUp {
  _id: string;
  SignUps: PartySignUp[];
  SortFactors: string;
  Roles: string[];
  Classes: string[];
  Keys: string[];
  PaymentRealms: string[];
  Answers: { [key: string]: boolean };
  AcceptedKeys: string[];
  Hide?: boolean;
}

export const SignUpCDs = [20, 5, 0, 0];

export function getPostedOffers() {
  const postedOffers = useSelector(
    (state: RootState) => state.offer.postedOffers
  );
  return postedOffers;
}

export function findPostedOfferById(id: string) {
  const postedOffers = getPostedOffers();
  return postedOffers[id];
}

function FilterEligibleCharacters(
  Characters: SignedUpCharacter[],
  Classes?: WoWClassType[],
  RoleClasses?: Record<WoWRoleType, WoWClassType[]>,
  Utilities?: WoWBuffsType[],
  ArmorTypes?: WoWArmorType[],
  Roles?: WoWRoleType[],
  Keys?: string[],
  KHOnly?: boolean,
  faction?: 'Alliance' | 'Horde'
): SignedUpCharacter[] {
  if (!Characters || Characters.length === 0) return [];

  return Characters.map((character) => {
    const isFactionMatch =
      faction === undefined || faction === character.Faction;
    const isClassMatch =
      !Classes || Classes?.length === 0 || Classes?.includes(character.Class);
    const isUtilityMatch =
      !Utilities ||
      Utilities?.length === 0 ||
      Utilities?.some((utility) =>
        WoWBuffs.find((buff) => buff.Name === utility)?.Classes.includes(
          character.Class
        )
      );
    const isArmorMatch =
      !ArmorTypes ||
      ArmorTypes?.length === 0 ||
      ArmorTypes?.includes(
        WoWClasses.find((wowclass) => wowclass.Name === character.Class)?.Armor
      );
    const isKeyholderMatch = !KHOnly || character.Key;
    const isKeyMatch =
      !Keys || Keys?.length === 0 || Keys?.includes(character.Key);

    // Apply the basic filters
    if (
      isFactionMatch &&
      isClassMatch &&
      isUtilityMatch &&
      isArmorMatch &&
      isKeyholderMatch &&
      isKeyMatch
    ) {
      const CharacterClass = WoWClasses.find(
        (wowclass) => wowclass.Name === character.Class
      );
      const RoleIOs: { [key: string]: number } = {};

      // Only add roles that are in the Roles filter and have a value > 0
      (Roles?.length > 0 ? Roles : WoWRoles.map((role) => role.Name))?.forEach(
        (role) => {
          if (
            CharacterClass.Roles.includes(role as WoWRoleType) &&
            role in character.RoleIOs &&
            (!RoleClasses ||
              RoleClasses[role]?.length === 0 ||
              RoleClasses[role]?.includes(character.Class)) &&
            character.RoleIOs[role] >= 0
          ) {
            RoleIOs[role] = character.RoleIOs[role];
          }
        }
      );

      // Only return characters with at least one eligible role
      if (Object.keys(RoleIOs).length > 0) {
        return { ...character, RoleIOs }; // Return modified character with filtered RoleIOs
      }
    }
    return undefined; // Return undefined if character doesn't match conditions
  }).filter(Boolean); // Remove falsy values (characters that don't match conditions)
}

export function IsGroupSignup(signup: GroupSignUp | PartySignUp) {
  return 'SignUps' in signup;
}

function GetSortFactor(signup: GroupSignUp | PartySignUp, SortFactor: string) {
  if (IsGroupSignup(signup)) {
    const GroupSignUp = signup as GroupSignUp;
    let Result = 0;
    for (let i = 0; i < GroupSignUp?.SignUps?.length; i++) {
      if (GroupSignUp?.SignUps[i]?.SortFactors?.[SortFactor] > Result) {
        Result = GroupSignUp?.SignUps[i]?.SortFactors?.[SortFactor];
      }
    }
    return Result;
  } else {
    const PartySignUp = signup as PartySignUp;
    return PartySignUp?.SortFactors[SortFactor];
  }
}

export function IsSignupEligible(
  singup: PartySignUp | GroupSignUp,
  Classes: WoWClassType[],
  RoleClasses: Record<WoWRoleType, WoWClassType[]>,
  Utilities: WoWBuffsType[],
  ArmorTypes: WoWArmorType[],
  Roles: WoWRoleType[],
  Keys: string[],
  KHOnly: boolean,
  PosterFriends: string[],
  faction?: 'Alliance' | 'Horde',
  MinSortFactos?: { [key: string]: number },
  FriendsOnly?: boolean
) {
  if (
    !Object.entries(MinSortFactos).every(([key, value]) => {
      if (GetSortFactor(singup, key) === undefined || !value) return true;
      return GetSortFactor(singup, key) >= value;
    })
  )
    return undefined;
  if (IsGroupSignup(singup)) {
    const GroupSignUp = singup as GroupSignUp;
    if (FriendsOnly && this.Poster.Friends) {
      let NoFriend = true;
      for (let j = 0; j < GroupSignUp.SignUps.length; j++) {
        if (this.Poster.Friends.includes(GroupSignUp.SignUps[j].BoosterID)) {
          NoFriend = false;
          break;
        }
      }
      if (NoFriend) return undefined;
    }
    let groupSignUps = [];
    let eligibleGroupMembers = 0;
    for (let j = 0; j < GroupSignUp.SignUps.length; j++) {
      const SignUp = GroupSignUp.SignUps[j];
      const eligibleCharacters = FilterEligibleCharacters(
        SignUp.EligibleCharacters,
        Classes,
        RoleClasses,
        Utilities,
        ArmorTypes,
        Roles,
        Keys,
        KHOnly,
        faction
      );
      if (eligibleCharacters.length > 0) {
        groupSignUps.push({
          ...SignUp,
          EligibleCharacters: eligibleCharacters
        });
        eligibleGroupMembers += 1;
      } else {
        groupSignUps.push({
          ...SignUp,
          EligibleCharacters: SignUp.EligibleCharacters
        });
      }
    }
    if (eligibleGroupMembers > 0) {
      return { ...GroupSignUp, SignUps: groupSignUps };
    }
  } else {
    const SignUp = singup as PartySignUp;
    if (
      FriendsOnly &&
      PosterFriends &&
      !PosterFriends?.includes(SignUp.BoosterID)
    ) {
      return undefined;
    }
    const eligibleCharacters = FilterEligibleCharacters(
      SignUp.EligibleCharacters,
      Classes,
      RoleClasses,
      Utilities,
      ArmorTypes,
      Roles,
      Keys,
      KHOnly,
      faction
    );
    if (eligibleCharacters.length > 0) {
      return { ...SignUp, EligibleCharacters: eligibleCharacters };
    }
  }
  return undefined;
}

export function SearchSignups(
  signups: (PartySignUp | GroupSignUp)[],
  Search: string
) {
  const singleOptions = {
    includeScore: true,
    threshold: 0.3,
    keys: [
      'BoosterDetails.UserName',
      'EligibleCharacters.Name',
      'EligibleCharacters.Realm',
      'SignUps.BoosterDetails.UserName',
      'SignUps.EligibleCharacters.Name',
      'SignUps.EligibleCharacters.Realm'
    ]
  };
  const groupOptions = {
    includeScore: true,
    threshold: 0.3,
    keys: [
      'SignUps.BoosterDetails.UserName',
      'SignUps.EligibleCharacters.Name',
      'SignUps.EligibleCharacters.Realm'
    ]
  };
  const singleFuse = new Fuse(signups, singleOptions);
  const groupFuse = new Fuse(signups, groupOptions);
  const results = [...singleFuse.search(Search), ...groupFuse.search(Search)];
  return results.map((result) => result.item);
}

export class PostedOffer {
  Offer: offerObjectType;

  OfferHandler: OfferObj;

  SignUps: (PartySignUp | GroupSignUp)[];

  Boosters: PartySignUp[];

  PendingSignUps: PartySignUp[];

  Poster: User;

  constructor(
    offer: PartyOffer,
    SignUps: (PartySignUp | GroupSignUp)[],
    Boosters: PartySignUp[],
    Poster: User
  ) {
    this.Offer = offer;
    this.OfferHandler = new OfferObj(offer);
    this.Poster = Poster;
    this.SignUps = SignUps
      ? SignUps?.filter((signup) => {
          return Boosters?.every((booster) => {
            return 'SignUps' in signup
              ? !Object.values(signup.SignUps)
                  .map((signup) => signup.BoosterID)
                  .includes(booster.BoosterID)
              : booster.BoosterID !== signup.BoosterID;
          });
        })
      : [];
    if (
      ['Completed', 'Canceled', 'Failed', 'Paid', 'Ongoing'].includes(
        offer?.Status
      )
    ) {
      this.PendingSignUps = [];
      this.Boosters = Boosters;
    } else {
      this.PendingSignUps = Boosters
        ? Boosters.filter((booster) => !booster.InviteResponse)
        : [];
      this.Boosters = Boosters
        ? Boosters.filter((booster) => booster.InviteResponse)
        : [];
    }
  }

  getSignUps(
    Classes?: WoWClassType[],
    RoleClasses?: Record<WoWRoleType, WoWClassType[]>,
    ArmorTypes?: WoWArmorType[],
    Roles?: WoWRoleType[],
    Utilities?: WoWBuffsType[],
    Keys?: string[],
    KHOnly?: boolean,
    faction?: 'Alliance' | 'Horde',
    SortFactor?: string,
    MinSortFactos?: { [key: string]: number },
    FriendsOnly?: boolean,
    Search?: string
  ): (PartySignUp | GroupSignUp)[] {
    let signUps: (PartySignUp | GroupSignUp)[] = [];
    let uniqueSignUps = [];
    let seen = new Set();
    const eligibleSignUps = this.SignUps.filter((signup) => {
      const isEligible = Object.entries(MinSortFactos ?? {}).every(
        ([key, value]) => {
          if (GetSortFactor(signup, key) === undefined || !value) return true;
          return GetSortFactor(signup, key) >= value;
        }
      );
      if (!isEligible) return false;
      const key = 'SignUps' in signup ? signup._id : signup.BoosterID;
      if (seen.has(key)) return false;
      seen.add(key);
      uniqueSignUps.push(signup);
      return true;
    });

    for (let i = 0; i < eligibleSignUps.length; i++) {
      if (IsGroupSignup(eligibleSignUps[i])) {
        const GroupSignUp = eligibleSignUps[i] as GroupSignUp;
        if (FriendsOnly && this.Poster.Friends) {
          let NoFriend = true;
          for (let j = 0; j < GroupSignUp.SignUps.length; j++) {
            if (
              this.Poster.Friends.includes(GroupSignUp.SignUps[j].BoosterID)
            ) {
              NoFriend = false;
              break;
            }
          }
          if (NoFriend) continue;
        }
        let groupSignUps = [];
        let ineligibleMembers = 0;
        for (let j = 0; j < GroupSignUp.SignUps.length; j++) {
          const SignUp = GroupSignUp.SignUps[j];
          const eligibleCharacters = FilterEligibleCharacters(
            SignUp.EligibleCharacters,
            Classes,
            RoleClasses,
            Utilities,
            ArmorTypes,
            Roles,
            Keys,
            KHOnly,
            faction
          );
          if (eligibleCharacters.length > 0) {
            groupSignUps.push({
              ...SignUp,
              EligibleCharacters: eligibleCharacters
            });
          } else {
            groupSignUps.push({
              ...SignUp,
              EligibleCharacters: SignUp.EligibleCharacters
            });
            ineligibleMembers += 1;
          }
        }
        if (groupSignUps.length > ineligibleMembers) {
          signUps.push({ ...GroupSignUp, SignUps: groupSignUps });
        }
      } else {
        const SignUp = eligibleSignUps[i] as PartySignUp;
        if (
          FriendsOnly &&
          this.Poster.Friends &&
          !this.Poster.Friends.includes(SignUp.BoosterID)
        ) {
          continue;
        }
        const eligibleCharacters = FilterEligibleCharacters(
          SignUp.EligibleCharacters,
          Classes,
          RoleClasses,
          Utilities,
          ArmorTypes,
          Roles,
          Keys,
          KHOnly,
          faction
        );
        if (eligibleCharacters.length > 0) {
          signUps.push({ ...SignUp, EligibleCharacters: eligibleCharacters });
        }
      }
      if (Search) {
        const singleOptions = {
          includeScore: true,
          threshold: 0.3,
          keys: [
            'BoosterDetails.UserName',
            'EligibleCharacters.Name',
            'EligibleCharacters.Realm',
            'SignUps.BoosterDetails.UserName',
            'SignUps.EligibleCharacters.Name',
            'SignUps.EligibleCharacters.Realm'
          ]
        };
        const groupOptions = {
          includeScore: true,
          threshold: 0.3,
          keys: [
            'SignUps.BoosterDetails.UserName',
            'SignUps.EligibleCharacters.Name',
            'SignUps.EligibleCharacters.Realm'
          ]
        };
        const singleFuse = new Fuse(signUps, singleOptions);
        const groupFuse = new Fuse(signUps, groupOptions);
        const results = [
          ...singleFuse.search(Search),
          ...groupFuse.search(Search)
        ];
        signUps = results.map((result) => result.item);
      }
    }

    // signUps = this.SignUps.map((signup) => {
    //   return IsSignupEligible(
    //     signup,
    //     Classes,
    //     ArmorTypes,
    //     Roles,
    //     Keys,
    //     KHOnly,
    //     this?.Poster?.Friends,
    //     faction,
    //     MinSortFactos,
    //     FriendsOnly
    //   );
    // }).filter(Boolean);

    if (SortFactor) {
      signUps?.sort((a, b) => {
        return GetSortFactor(b, SortFactor) - GetSortFactor(a, SortFactor);
      });
    }

    return signUps;
  }

  calculateCut(signup: PartySignUp) {
    if (!signup) return 0;
    let Cut = this.OfferHandler.CutPerBooster();
    if ('KeyBonusPayment' in this.Offer) {
      if (signup.AcceptedKey) {
        Cut += this.Offer.KeyBonusPayment;
      }
    }
    if ('RoleBonusPayment' in this.Offer) {
      if (signup.AcceptedRole) {
        Cut += this.Offer.RoleBonusPayment[signup.AcceptedRole] || 0;
      }
    }
    if ('GroupLeaderBonus' in this.Offer) {
      if (signup.GroupLeader) {
        Cut += this.Offer.GroupLeaderBonus;
      }
    }
    if (
      'CoordinatorBonusPercentage' in this.Offer &&
      'Coordinator' in this.Offer
    ) {
      if (signup.BoosterID === this.Offer.Coordinator) {
        Cut += this.OfferHandler.CoordinatorCut();
      }
    }
    return Cut + signup.BonusTip;
  }
}

export function MakeMockSignUp(User: User): PartySignUp {
  return {
    BoosterID: User._id,
    BoosterDetails: User.DiscordDetails,
    TimeStamp: Date.now(),
    BoostsCompleted: 0,
    Reviews: [0, 0],
    BNet: User.BNet,
    Roles: [],
    SortFactors: {},
    RealmPaymentCharacters: [],
    AcceptedCharacter: null,
    ChosenPaymentCharacter: null,
    AcceptedRole: null,
    StatusReport: false,
    ScamReport: false,
    Note: '',
    Premium: User.Premium.Tier,
    BonusTip: 0,
    GoldTaken: false,
    GroupLeader: false,
    Type: 'Party',
    _id: 'mocksignup',
    Hide: false,
    MainIO: 0,
    Keys: [],
    EligibleCharacters: [MakeMockCharacter(User)],
    AcceptedKey: ''
  };
}
