import { Avatar, Box, Typography, TypographyProps } from '@mui/material';
import Text from 'src/components/Text';
import { Character } from 'src/models/character';

export interface PaymentRealmProps extends Omit<TypographyProps, 'color'> {
  PaymentRealms: string[];
  PaymentFactions: ('Horde' | 'Alliance')[];
  PaymentCharacter?: Character;
}

export const PaymentRealm = ({
  PaymentRealms,
  PaymentFactions,
  PaymentCharacter,
  ...typographyProps
}: PaymentRealmProps) => {
  const factions = PaymentCharacter?.Faction
    ? [PaymentCharacter.Faction]
    : PaymentFactions;
  const string = PaymentCharacter?.Name
    ? `${PaymentCharacter.Name}-${PaymentCharacter?.Realm.replace('-', '')}`
    : PaymentRealms.join(', ');
  const Color =
    factions?.length === 2
      ? 'primary'
      : factions?.includes('Horde')
      ? 'error'
      : 'info';
  return (
    <Box display={'inline-flex'}>
      {!PaymentCharacter
        ? factions?.length < 2 &&
          (factions?.includes('Horde') ? (
            <Avatar
              src={'/static/images/WoW/faction/Horde.png'}
              sx={{ height: 20, width: 20, mr: 0.5 }}
            />
          ) : (
            <Avatar
              src={'/static/images/WoW/faction/Alliance.png'}
              sx={{ height: 20, width: 20, mr: 0.5 }}
            />
          ))
        : null}
      <Typography {...typographyProps}>
        <Text color={Color}>{string}</Text>
      </Typography>
    </Box>
  );
};

export default PaymentRealm;
