import { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import TopBarContent from './TopBarContent';
import BottomBarContent from './BottomBarContent';
import SidebarContent from './SidebarContent';
import ChatContent from './ChatContent';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

import Scrollbar from 'src/components/Scrollbar';

import {
  Box,
  styled,
  Divider,
  Drawer,
  IconButton,
  useTheme,
  Card,
  Typography,
  Tooltip
} from '@mui/material';
import { Message } from 'src/models/message';
import { AppDispatch, RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'src/models/user';

const RootWrapper = styled(Box)(
  ({ theme }) => `
       display: flex;
       height: 100%;  // Set height to 100%
       min-height: 500px;
`
);

const Sidebar = styled(Box)(
  ({ theme }) => `
        width: 300px;
        background: ${theme.colors.alpha.white[100]};
        border-right: ${theme.colors.alpha.black[10]} solid 1px;
`
);

const ChatWindow = styled(Box)(
  () => `
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;  // let it grow within its parent container
`
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
        padding: ${theme.spacing(2)};
        align-items: center;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background: ${theme.colors.alpha.white[100]};
`
);

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 50px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 50px;
        z-index: 3;
  }
`
);

interface MessengerProps {
  Messages: Message[];
  MessagesType: 'Direct' | 'Group';
  ID: string;
  ChatName?: string;
  ChatPictureURL?: string;
}

function Messenger({
  Messages,
  MessagesType,
  ID,
  ChatName,
  ChatPictureURL
}: MessengerProps) {
  const exclude = useSelector((state: RootState) =>
    state?.user?.user?.Messages?.[MessagesType]?.[ID]?.map((m) => m._id)
  );
  const dispatch: AppDispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);

  const chatContentRef = useRef(null);

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollToBottom();
    }
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'mark_read',
        data: { id: ID, type: MessagesType }
      }
    });
  }, [Messages]);

  useEffect(() => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'get_messages',
        data: {
          type: MessagesType,
          id: ID,
          exclude: exclude ?? []
        }
      }
    });
  }, [ID, MessagesType]);

  const uniqueMessages = Messages?.filter(
    (v, i, a) => a.findIndex((t) => t._id === v._id) === i
  );

  return (
    <Card sx={{ height: '100%', position: 'relative' }}>
      <RootWrapper>
        {/* <DrawerWrapperMobile
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <Scrollbar>
            <SidebarContent />
          </Scrollbar>
        </DrawerWrapperMobile> */}
        {/* <Sidebar
          sx={{
            display: { xs: 'none', lg: 'inline-block' }
          }}
        >
          <Scrollbar>
            <SidebarContent />
          </Scrollbar>
        </Sidebar> */}
        <ChatWindow>
          <ChatTopBar
            sx={{
              display: 'inline-block'
            }}
          >
            {/* <IconButtonToggle
              sx={{
                display: { lg: 'none', xs: 'flex' },
                mr: 2
              }}
              color="primary"
              onClick={handleDrawerToggle}
              size="small"
            >
              <MenuTwoToneIcon />
            </IconButtonToggle> */}
            <TopBarContent
              Messages={Messages}
              MessagesType={MessagesType}
              ID={ID}
              ChatName={ChatName}
              ChatPictureURL={ChatPictureURL}
            />
          </ChatTopBar>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Scrollbar ref={chatContentRef}>
              <ChatContent Messages={uniqueMessages} />
            </Scrollbar>
          </Box>

          <Divider />
          <BottomBarContent
            MessageType={MessagesType}
            ID={ID}
            GroupName={ChatName}
            GroupPictureURL={ChatPictureURL}
          />
        </ChatWindow>
      </RootWrapper>
    </Card>
  );
}

export default Messenger;
