import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { Box, Button, CssBaseline, Dialog, Typography } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import React, { useEffect, useState } from 'react';
import { RootState, AppDispatch } from './app/store';
import { useDispatch, useSelector } from 'react-redux';
import ReconnectingOverlay from './SocketReconnecting';
import SocketPopup from './components/Utility/DiscordEmbed';
import { clearOldOffers } from 'src/features/offerSlice';
import { InviteModal } from './components/WoW/Invitation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ErrorBoundary from './ErrorBoundry';
import StackedAnnouncements from './components/Announcements';
import { announcement } from './models/announcement';
import { EmergencyNotifModal } from './components/WoW/EmergencyNotifModal';

function App() {
  const content = useRoutes(router);
  const dispatch: AppDispatch = useDispatch();
  const login = useSelector((state: RootState) => state.login);
  const user = useSelector((state: RootState) => state.user.user);
  const [isRefresh, setIsRefresh] = useState(
    sessionStorage.getItem('isRefreshing') === 'true'
  );
  const updateResults = useSelector((state: RootState) => state.updateResponse);
  const [response, setResponse] = useState({
    status: '',
    message: ''
  });
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const handleCloseResponseModal = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    setResponseModalOpen(false);
    setResponse({
      status: '',
      message: ''
    });
  };
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  useEffect(() => {
    const handleUnload = () => {
      if (isConnected) {
        dispatch({
          type: 'socket/disconnect'
        });
      }
      sessionStorage.setItem('isRefreshing', 'true');
    };

    // Add the event listener
    window.addEventListener('unload', handleUnload);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('unload', handleUnload);
      sessionStorage.setItem('isRefreshing', 'false');
    };
  }, [isConnected, dispatch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (window.location.pathname === '/discord_oauth' && code) {
      localStorage.setItem('discordCode', code);
      window.close();
    }
    if (window.location.pathname === '/blizzard_oauth' && code) {
      localStorage.setItem('blizzardCode', code);
      window.close();
    }
  }, [isConnected]);

  useEffect(() => {
    if (!isRefresh && login.loggedIn) {
      dispatch({
        type: 'socket/Message/send',
        payload: {
          type: 'initialization',
          data: {
            CachedDiscordDetailsIDs: Object.keys(
              user?.CachedDiscordDetails || {}
            )
          },
          silent: true
        }
      });
      sessionStorage.removeItem('isRefreshing');
    }
  }, [isRefresh, dispatch]);

  useEffect(() => {
    if (updateResults.showned || updateResults.message === '') {
      return;
    }
    if (updateResults && updateResults.success) {
      setResponse({
        status: 'Success',
        message: updateResults.message
      });
      dispatch({ type: 'response/acknowledge' });
    } else if (updateResults && !updateResults.success) {
      setResponse({
        status: 'Error',
        message: updateResults.message
      });
      dispatch({ type: 'response/acknowledge' });
    }
    setResponseModalOpen(true);
  }, [updateResults]);

  useEffect(() => {
    dispatch({ type: 'login/check' });
    const initalizationInterval = setInterval(() => {
      if (login.loggedIn) {
        dispatch({
          type: 'socket/Message/send',
          payload: {
            type: 'initialization',
            data: {
              CachedDiscordDetailsIDs: Object.keys(
                user?.CachedDiscordDetails || {}
              )
            },
            silent: true
          }
        });
      }
    }, 60 * 60 * 1000);
    const intervalId = setInterval(() => {
      if (login.loggedIn) {
        dispatch({ type: 'offer/cleanup' });
      }
    }, 60 * 1000);
    const usercleanupInterval = setInterval(() => {
      if (login.loggedIn) {
        dispatch({ type: 'user/cleanups' });
      }
    }, 60 * 1000);
    const connectionCheckInterval = setInterval(() => {
      dispatch({ type: 'login/check' });
    }, 5 * 60 * 1000);
    return () => {
      clearInterval(initalizationInterval);
      clearInterval(intervalId);
      clearInterval(usercleanupInterval);
      clearInterval(connectionCheckInterval);
    };
  }, [dispatch]);

  useEffect(() => {
    if (user?.Suspended && isConnected) {
      dispatch({
        type: 'socket/Message/send',
        payload: {
          type: 'update_suspension'
        }
      });
    }
  }, [user, isConnected, dispatch]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        {content}
        <ReconnectingOverlay />
        <SocketPopup />
        <InviteModal />
        <EmergencyNotifModal />
        <Dialog
          open={responseModalOpen}
          onClose={handleCloseResponseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          fullWidth={true}
        >
          <Box margin={'20px'}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign={'center'}
            >
              {response.status}
              {response.status === 'Success' ? '!' : ''}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              textAlign={'center'}
            >
              {response.message}
            </Typography>
            <Box textAlign={'center'}>
              <Button
                sx={{
                  width: '20%',
                  marginLeft: '5px',
                  marginTop: '10px',
                  borderRadius: '15px'
                }}
                variant="contained"
                color="success"
                size="small"
                onClick={handleCloseResponseModal}
              >
                OK!
              </Button>
            </Box>
          </Box>
        </Dialog>
        <Dialog
          open={user.Suspended}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          fullWidth={true}
        >
          <Box margin={'20px'}>
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h2"
              textAlign={'center'}
              color={'error'}
            >
              Suspended!
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              textAlign={'center'}
              variant="h4"
            >
              You are suspended from using the service. Please open a support
              ticket (you have to join {process.env.REACT_APP_DISCORD})
            </Typography>
            <Box textAlign={'center'} mt={3}>
              <Button
                variant="contained"
                color="warning"
                size="medium"
                href="https://discord.com/channels/698426461534814270/719640038685671575"
              >
                Open a ticket
              </Button>
            </Box>
          </Box>
        </Dialog>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
