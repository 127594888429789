import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import { mongoUpdate } from './mongoUpdate';
import {
  WeekdaysStatistics,
  LevelsStatistics,
  RoleClassStatistics,
  RealmsStatistics,
  TotalCutsStatistics
} from 'src/models/statistics';

const migrations = {
  1: (state) => {
    const migratedState = { ...state };
    if (!migratedState?.Personal)
      migratedState.Personal = {
        TotalCuts: {
          Totals: {},
          LastUpdated: 0
        },
        Weekdays: {
          Weekdays: {
            Runs: {},
            Offers: {}
          },
          LastUpdated: 0
        },
        Levels: {
          Levels: {
            Runs: {},
            Offers: {}
          },
          LastUpdated: 0
        },
        RoleClass: {
          Tank: {
            Runs: {},
            Offers: {}
          },
          Healer: {
            Runs: {},
            Offers: {}
          },
          DPS: {
            Runs: {},
            Offers: {}
          },
          LastUpdated: 0
        },
        Realms: {
          Realms: {
            Runs: {},
            Offers: {}
          },
          LastUpdated: 0
        }
      };
    return migratedState;
  }
};

const persistConfig = {
  key: 'statistics',
  storage: storage,
  version: 1,
  migrate: createMigrate(migrations)
};

interface statisticsProps {
  TotalCuts: TotalCutsStatistics;
  Weekdays: WeekdaysStatistics;
  Levels: LevelsStatistics;
  RoleClass: RoleClassStatistics;
  Realms: RealmsStatistics;
  Personal: {
    TotalCuts: TotalCutsStatistics;
    Weekdays: WeekdaysStatistics;
    Levels: LevelsStatistics;
    RoleClass: RoleClassStatistics;
    Realms: RealmsStatistics;
  };
}
const initialState: statisticsProps = {
  TotalCuts: {
    Totals: {},
    LastUpdated: 0
  },
  Weekdays: {
    Weekdays: {
      Runs: {},
      Offers: {}
    },
    LastUpdated: 0
  },
  Levels: {
    Levels: {
      Runs: {},
      Offers: {}
    },
    LastUpdated: 0
  },
  RoleClass: {
    Tank: {
      Runs: {},
      Offers: {}
    },
    Healer: {
      Runs: {},
      Offers: {}
    },
    DPS: {
      Runs: {},
      Offers: {}
    },
    LastUpdated: 0
  },
  Realms: {
    Realms: {
      Runs: {},
      Offers: {}
    },
    LastUpdated: 0
  },
  Personal: {
    TotalCuts: {
      Totals: {},
      LastUpdated: 0
    },
    Weekdays: {
      Weekdays: {
        Runs: {},
        Offers: {}
      },
      LastUpdated: 0
    },
    Levels: {
      Levels: {
        Runs: {},
        Offers: {}
      },
      LastUpdated: 0
    },
    RoleClass: {
      Tank: {
        Runs: {},
        Offers: {}
      },
      Healer: {
        Runs: {},
        Offers: {}
      },
      DPS: {
        Runs: {},
        Offers: {}
      },
      LastUpdated: 0
    },
    Realms: {
      Realms: {
        Runs: {},
        Offers: {}
      },
      LastUpdated: 0
    }
  }
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (
      state,
      action: PayloadAction<{
        TotalCuts?: TotalCutsStatistics[];
        Weekdays?: WeekdaysStatistics;
        Levels?: LevelsStatistics;
        RoleClass?: RoleClassStatistics;
        Realms?: RealmsStatistics;
      }>
    ) => {
      for (const key in action.payload) {
        if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
          state[key] = action.payload[key];
        }
      }
    },
    mongodbUpdate: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state = mongoUpdate(state, action.payload);
    }
  }
});

export const { reset, update, mongodbUpdate } = statisticsSlice.actions;
const persistedReducer = persistReducer(persistConfig, statisticsSlice.reducer);
export default persistedReducer;
