import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'src/models/user';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { mongoUpdate, updateMany } from './mongoUpdate';

const persistConfig = {
  key: 'user',
  storage: storage
};
const initialState = {
  user: { _id: '', CachedDiscordDetails: {} } as User,
  onlineStatuses: {} as { [key: string]: boolean }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      const CachedDiscordDetails = state?.user?.CachedDiscordDetails;
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
      state.user = { ...state.user, CachedDiscordDetails };
      state.onlineStatuses = {};
    },
    update: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.user = { ...state.user, ...action.payload };
    },
    mongodbUpdate: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.user = mongoUpdate(state.user, action.payload);
    },
    updateOnlineStatuses: (
      state,
      action: PayloadAction<{ [key: string]: boolean }>
    ) => {
      state.onlineStatuses = { ...state.onlineStatuses, ...action.payload };
    },
    newMessage: (
      state,
      action: PayloadAction<{
        type: 'Group' | 'Direct';
        id: string;
        content: string;
        silent: boolean;
      }>
    ) => {
      const { type, id, content } = action.payload;
      state.user = mongoUpdate(state.user, {
        $addToSet: { [`Messages.${type}.${id}`]: content }
      });
    },
    AddReader: (
      state,
      action: PayloadAction<{
        type: 'Group' | 'Direct';
        id: string;
        reader: string;
      }>
    ) => {
      const { type, id, reader } = action.payload;
      if (state.user?.Messages?.[type]?.[id] !== undefined) {
        for (const message of state.user?.Messages?.[type]?.[id]) {
          if (!message.Readers?.includes(reader)) {
            message.Readers?.push(reader);
          }
        }
      }
    },
    DeleteMessages: (
      state,
      action: PayloadAction<{
        type: 'Group' | 'Direct';
        id: string;
      }>
    ) => {
      const { type, id } = action.payload;
      const isMaintained = state.user?.MaintainedChatRooms?.includes(id);
      if (isMaintained) {
        state.user = mongoUpdate(state.user, {
          $set: {
            [`Messages.${type}.${id}`]:
              state.user?.Messages?.[type]?.[id]?.[
                state.user?.Messages?.[type]?.[id].length - 1
              ]
          }
        });
      } else {
        state.user = mongoUpdate(state.user, {
          $unset: { [`Messages.${type}.${id}`]: '' }
        });
      }
    }
  }
});

export const {
  reset,
  update,
  mongodbUpdate,
  newMessage,
  AddReader,
  DeleteMessages,
  updateOnlineStatuses
} = userSlice.actions;
const persistedReducer = persistReducer(persistConfig, userSlice.reducer);
export default persistedReducer;
