export type WoWBuffsType =
  | 'Battle Resurrection'
  | 'Bloodlust'
  | 'Chaos Brand'
  | 'Mark of the Wild'
  | 'Blessing of the Bronze'
  | "Hunter's Mark"
  | 'Arcane Intellect'
  | 'Mystic Touch'
  | 'Devotion Aura'
  | 'Retribution Aura'
  | 'Fortitude'
  | 'Atrophic Poison'
  | 'Skyfury'
  | 'Battle Shout'
  | 'Healthstone';

export interface WoWBuff {
  Name: WoWBuffsType;
  Emoji: string;
  Classes: string[];
}

function MakeBuffIcon(name: string): string {
  return `/static/images/WoW/buffs/${name}.png`;
}

export const WoWBuffs: WoWBuff[] = [
  {
    Name: 'Battle Resurrection',
    Emoji: MakeBuffIcon('Battle_Resurrection'),
    Classes: ['Death Knight', 'Druid', 'Paladin', 'Warlock']
  },
  {
    Name: 'Bloodlust',
    Emoji: MakeBuffIcon('Bloodlust'),
    Classes: ['Shaman', 'Hunter', 'Mage', 'Evoker']
  },
  {
    Name: 'Chaos Brand',
    Emoji: MakeBuffIcon('Chaos_Brand'),
    Classes: ['Demon Hunter']
  },
  {
    Name: 'Mark of the Wild',
    Emoji: MakeBuffIcon('Mark_of_the_Wild'),
    Classes: ['Druid']
  },
  {
    Name: 'Blessing of the Bronze',
    Emoji: MakeBuffIcon('Blessing_of_the_Bronze'),
    Classes: ['Evoker']
  },
  {
    Name: "Hunter's Mark",
    Emoji: MakeBuffIcon('Hunters_Mark'),
    Classes: ['Hunter']
  },
  {
    Name: 'Arcane Intellect',
    Emoji: MakeBuffIcon('Arcane_Intellect'),
    Classes: ['Mage']
  },
  {
    Name: 'Mystic Touch',
    Emoji: MakeBuffIcon('Mystic_Touch'),
    Classes: ['Monk']
  },
  {
    Name: 'Devotion Aura',
    Emoji: MakeBuffIcon('Devotion_Aura'),
    Classes: ['Paladin']
  },
  {
    Name: 'Retribution Aura',
    Emoji: MakeBuffIcon('Retribution_Aura'),
    Classes: ['Paladin']
  },
  {
    Name: 'Fortitude',
    Emoji: MakeBuffIcon('Fortitude'),
    Classes: ['Priest']
  },
  {
    Name: 'Atrophic Poison',
    Emoji: MakeBuffIcon('Atrophic_Poison'),
    Classes: ['Rogue']
  },
  {
    Name: 'Skyfury',
    Emoji: MakeBuffIcon('Skyfury'),
    Classes: ['Shaman']
  },
  {
    Name: 'Battle Shout',
    Emoji: MakeBuffIcon('Battle_Shout'),
    Classes: ['Warrior']
  },
  {
    Name: 'Healthstone',
    Emoji: MakeBuffIcon('Healthstone'),
    Classes: ['Warlock']
  }
];
