import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { announcement } from 'src/models/announcement';
import { mongoUpdate } from './mongoUpdate';

const persistConfig = {
  key: 'announcements',
  storage: storage
};

interface announcementsProps {
  announcements: { [key: string]: announcement };
  emergencyNotification?: {
    title: string;
    message?: string;
    redirectURL?: string;
  };
}
const initialState: announcementsProps = {
  announcements: {}
};

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (state, action: PayloadAction<announcement>) => {
      if (state.announcements[action.payload._id]) {
        state.announcements[action.payload._id] = {
          ...state.announcements[action.payload._id],
          ...action.payload
        };
      } else {
        state.announcements[action.payload._id] = action.payload;
      }
    },
    mongodbUpdate: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.announcements = mongoUpdate(state.announcements, action.payload);
    },
    markRead: (state, action: PayloadAction<string>) => {
      if (!state.announcements?.[action.payload]) {
        return;
      }
      state.announcements[action.payload].read = true;
    },
    setEmergencyNotification: (
      state,
      action: PayloadAction<announcementsProps['emergencyNotification']>
    ) => {
      state.emergencyNotification = action.payload;
    }
  }
});

export const {
  reset,
  update,
  mongodbUpdate,
  markRead,
  setEmergencyNotification
} = announcementsSlice.actions;
const persistedReducer = persistReducer(
  persistConfig,
  announcementsSlice.reducer
);
export default persistedReducer;
