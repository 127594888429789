import { useSelector } from 'react-redux';
import { BlackListItem, getBlacklist } from './blacklist';
import {
  Character,
  MythicPlusCharacter,
  RaidCharacter,
  SignedUpCharacter,
  getCharacter
} from './character';
import { PremiumStatus, getPremium } from './premium';
import { RootState } from 'src/app/store';
import { AutoSignupStatus, MinimumOfferRequirements } from './autosignup';
import { offerObjectType, OfferTypeNames } from './offer';
import { UserMessages } from './message';
import { PricelistItem } from './pricelist';
import { signupFilters } from 'src/features/offerSlice';

export interface DiscordDetails {
  Username: string;
  AvatarURL?: string;
  LastUpdate?: number;
  Karma?: number;
  CompetitionPoints?: number;
  Medals?: string[];
  MainMedal?: string;
  CustomName?: string;
  CustomAvatar?: string;
  CustomBackground?: string;
  CustomSignupBackground?: string;
  Descripion?: string;
  Premium?: number;
  PresonalColor?: number;
}

export interface Earning {
  PerMonth: { [key: string]: number };
  PerDay: { [key: string]: number };
}

export interface CurrentGroup {
  Lead: string;
  Members: string[];
  AutoSignupStatus?: AutoSignupStatus;
}

export interface User {
  _id: string;
  DiscordDetails?: DiscordDetails;
  BNet?: string;
  Characters?: Character[] | MythicPlusCharacter[] | RaidCharacter[];
  PaymentCharacters?: Character[];
  DefaultPaymentRealms?: { [key: string]: string[] };
  Stats?: { [key: string]: { [key: string]: number } };
  RoleBan?: { [key: string]: boolean };
  Honor?: { [key: string]: number };
  Score?: { [key: string]: number };
  AutoBanImmune?: boolean;
  Premium?: PremiumStatus;
  Suspended?: boolean;
  Blacklist?: { [key: number]: BlackListItem };
  Friends?: string[];
  PingList?: string[];
  AutoRolesOnUpdate?: boolean;
  OfferThumbnail?: string;
  PersonalHuokanRoleID?: number;
  PresonalColor?: number;
  MutePremiumNotification?: boolean;
  ResponseTime?: number;
  ReviewNotification?: boolean;
  AcceptMessages?: boolean;
  DefaultPosterNote?: string;
  TotalEarnings?: Earning;
  PosterEarnings?: Earning;
  AutoSignupSettings?: MinimumOfferRequirements;
  AutoSignupCharacters?: SignedUpCharacter[];
  AutoSignupStatus?: AutoSignupStatus;
  RecentRuns?: { [key: string]: offerObjectType };
  PostProfiles?: { [key: string]: any };
  RecentPosts?: { [key: string]: any };
  Messages?: UserMessages;
  CachedDiscordDetails?: { [key: string]: DiscordDetails };
  CompetitionPoints?: number;
  CurrentGroup?: CurrentGroup;
  Karma?: number;
  DefaultLeaders?: string[];
  Medals?: string[];
  MainMedal?: string;
  PriceList?: PricelistItem[];
  CustomName?: string;
  CustomAvatar?: string;
  CustomBackground?: string;
  CustomSignupBackground?: string;
  Descripion?: string;
  Notes?: { [key: string]: string };
  MaintainedChatRooms?: string[];
  AutofillProfiles?: { [key: string]: signupFilters };
}

export class UserObj {
  user: User;

  constructor(user: User) {
    this.user = user;
  }
}

export const getPriceList = (offerType?: OfferTypeNames) => {
  const user = getUser();
  return offerType
    ? user.PriceList?.filter((item) => item.Category === offerType)
    : user.PriceList;
};

export const getBadgeColor = (status: boolean) => {
  return status ? 'success' : status === undefined ? 'secondary' : 'warning';
};

export function getUser() {
  const user = useSelector((state: RootState) => state.user.user);
  if (user) {
    return user;
  }
  return {
    _id: '410635076842422293',
    DiscordDetails: {
      Username: 'Iman',
      AvatarURL:
        'https://cdn.discordapp.com/avatars/410635076842422293/feede4dda1376e225e01ca124c99ed5c.webp?size=128'
    },
    BNet: 'Iman#11222',
    DefaultPaymentRealms: {
      US: [],
      EU: []
    },
    Stats: {
      Advertiser: {
        Rank: 3
      },
      Support: {
        Rank: 0
      },
      Booster: {
        MythicPlusRank: 6,
        LevelingRank: 0,
        PvPRank: 0,
        RaidRank: 0,
        TorghastRank: 0
      }
    },
    RoleBan: {
      Tank: false,
      Healer: false,
      DPS: false
    },
    Honor: {
      Gained: 0,
      Given: 0
    },
    Score: {
      MythicPlus: 1016,
      Raid: 0,
      Leveling: 0,
      PvP: 0,
      Advertising: 262,
      Torghast: 0,
      Support: 0
    },
    Suspended: false,
    AutoBanImmune: false,
    Characters: [
      getCharacter(),
      getCharacter(),
      getCharacter(),
      getCharacter()
    ],
    PaymentCharacters: [
      getCharacter(),
      getCharacter(),
      getCharacter(),
      getCharacter(),
      getCharacter()
    ],
    Blacklist: {
      '168273596064595968': getBlacklist()
    },
    Premium: getPremium(),
    AutoRolesOnUpdate: true,
    OfferThumbnail:
      'https://cdn.discordapp.com/ephemeral-attachments/1081503861111525456/1…',
    PresonalColor: 11887459,
    ResponseTime: 50,
    Friends: ['822457540310204436', '161310382080786432', '310873975876222988'],
    PingList: ['255043824915709952', '161310382080786432'],
    ReviewNotification: true,
    MutePremiumNotification: false,
    AcceptMessages: true,
    DefaultPosterNote: 'TEST - Iman\nDO NOT SIGNUP'
  } as User;
}
