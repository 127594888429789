import { Box, Container, Link, Typography, styled } from '@mui/material';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1">
            Copyright &copy; {new Date().getFullYear()} - WoW LFG
          </Typography>
        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          Powered by bloomui.com
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        gap={3}
        py={1}
        textAlign="center"
      >
        <Link href="/tos" color="inherit" underline="hover" variant="subtitle1">
          Terms of Service
        </Link>
        <Link
          href="/privacy_policy"
          color="inherit"
          underline="hover"
          variant="subtitle1"
        >
          Privacy Policy
        </Link>
        <Link
          href="/disclaimers"
          color="inherit"
          underline="hover"
          variant="subtitle1"
        >
          Disclaimers
        </Link>
        <Link
          href="/about_us"
          color="inherit"
          underline="hover"
          variant="subtitle1"
        >
          About Us
        </Link>
        <Link
          href="/contact_us"
          color="inherit"
          underline="hover"
          variant="subtitle1"
        >
          Contact Us
        </Link>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
