export interface MessageNotificationSettings {
  Direct: boolean;
  Group: boolean;
  MutedIDs: string[];
  sound: boolean;
  soundVolume: number;
  windowsNotif: boolean;
}

export interface Message {
  Sender: string;
  Content: string;
  Mentions: number[];
  PictureURL?: string;
  TimeStamp: number;
  Readers: string[];
  EligibleReaders?: string[];
  _id: string;
}

export interface DirectMessage extends Message {
  Recipient: string;
}

export interface GroupMessage extends Message {
  RoomID: string;
  GroupName?: string;
  GroupPictureURL?: string;
}

export interface UserMessages {
  Direct: { [key: string]: DirectMessage[] };
  Group: { [key: string]: GroupMessage[] };
}

export function unReads(messages: Message[], userID: string): number {
  if (messages === undefined || messages.length === 0) {
    return 0;
  }
  const uniqueMessages = Array.isArray(messages)
    ? messages.filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i)
    : [];
  return uniqueMessages.filter(
    (message) => !message.Readers.includes(userID) && message.Sender !== userID
  ).length;
}

export function getRecentMessages(
  userMessages: UserMessages,
  exludeIDs: string[],
  limit?: number
): { [k: string]: GroupMessage[] | DirectMessage[] } {
  const recentMessages = Object.fromEntries(
    Object.entries({ ...userMessages?.Group, ...userMessages?.Direct })
      .filter(
        ([member, messages]) =>
          !exludeIDs.includes(member) &&
          messages &&
          messages.length > 0 &&
          (messages[messages.length - 1]?.Content ||
            messages[messages.length - 1]?.PictureURL)
      )
      .sort((a, b) => {
        if (!a[1] && a[1].length === 0) {
          return 1;
        }
        if (!b[1] && b[1].length === 0) {
          return -1;
        }
        const aDate = new Date(a[1][a[1].length - 1].TimeStamp);
        const bDate = new Date(b[1][b[1].length - 1].TimeStamp);
        return bDate.getTime() - aDate.getTime();
      })
  );
  return limit
    ? Object.fromEntries(Object.entries(recentMessages).slice(0, limit))
    : recentMessages;
}

export function SampleGroupMessage(): GroupMessage {
  return {
    Sender: '410635076842422293',
    Content: 'Sample Content',
    Mentions: [],
    TimeStamp: Date.now(),
    Readers: [],
    _id: 'Sample ID',
    RoomID: 'Sample Room ID'
  };
}
