import { WoWArmorType } from './wowarmors';
import { WoWBuffsType } from './wowbuffs';
import { WoWTierToken } from './wowtiertokens';

export type WoWRoleType = 'Tank' | 'Healer' | 'DPS';
export type WoWClassType =
  | 'Death Knight'
  | 'Demon Hunter'
  | 'Druid'
  | 'Evoker'
  | 'Hunter'
  | 'Mage'
  | 'Monk'
  | 'Paladin'
  | 'Priest'
  | 'Rogue'
  | 'Shaman'
  | 'Warlock'
  | 'Warrior';

export interface Spec {
  Name: string;
  Role: WoWRoleType;
  Emoji: string;
}

export interface WoWClass {
  Name: string;
  Emoji: string;
  Color: string;
  Short: string;
  Specs: Spec[];
  Roles?: WoWRoleType[];
  Armor: WoWArmorType;
  Tier: WoWTierToken;
  Buffs: WoWBuffsType[];
}

export interface WoWRole {
  Name: WoWRoleType;
  Emoji: string;
}

function MakeClassIcon(name: string): string {
  return `/static/images/WoW/Class/${name}.png`;
}

function MakeSpecIcon(name: string): string {
  return `/static/images/WoW/Spec/${name}.png`;
}

function MakeRoleIcon(name: string): string {
  return `/static/images/WoW/Roles/${name}.png`;
}

export const WoWRoles: WoWRole[] = [
  {
    Name: 'Tank',
    Emoji: MakeRoleIcon('Tank')
  },
  {
    Name: 'Healer',
    Emoji: MakeRoleIcon('Healer')
  },
  {
    Name: 'DPS',
    Emoji: MakeRoleIcon('DPS')
  }
];

export const Classes: WoWClass[] = [
  {
    Name: 'Death Knight',
    Emoji: MakeClassIcon('Death Knight'),
    Color: '#C41E3A',
    Short: 'DK',
    Specs: [
      {
        Name: 'Blood',
        Role: 'Tank',
        Emoji: MakeSpecIcon('DeathKnight-Blood')
      },
      {
        Name: 'Frost',
        Role: 'DPS',
        Emoji: MakeSpecIcon('DeathKnight-Frost')
      },
      {
        Name: 'Unholy',
        Role: 'DPS',
        Emoji: MakeSpecIcon('DeathKnight-Unholy')
      }
    ],
    Roles: ['Tank', 'DPS'],
    Armor: 'Plate',
    Tier: 'Dreadful',
    Buffs: ['Battle Resurrection']
  },
  {
    Name: 'Demon Hunter',
    Emoji: MakeClassIcon('Demon Hunter'),
    Color: '#A330C9',
    Short: 'DH',
    Specs: [
      {
        Name: 'Havoc',
        Role: 'DPS',
        Emoji: MakeSpecIcon('DemonHunter-Havoc')
      },
      {
        Name: 'Vengeance',
        Role: 'Tank',
        Emoji: MakeSpecIcon('DemonHunter-Vengeance')
      }
    ],
    Roles: ['Tank', 'DPS'],
    Armor: 'Leather',
    Tier: 'Dreadful',
    Buffs: ['Chaos Brand']
  },
  {
    Name: 'Druid',
    Emoji: MakeClassIcon('Druid'),
    Color: '#FF7C0A',
    Short: 'Druid',
    Specs: [
      {
        Name: 'Balance',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Druid-Balance')
      },
      {
        Name: 'Feral',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Druid-Feral')
      },
      {
        Name: 'Guardian',
        Role: 'Tank',
        Emoji: MakeSpecIcon('Druid-Guardian')
      },
      {
        Name: 'Restoration',
        Role: 'Healer',
        Emoji: MakeSpecIcon('Druid-Restoration')
      }
    ],
    Roles: ['Tank', 'DPS', 'Healer'],
    Armor: 'Leather',
    Tier: 'Mystic',
    Buffs: ['Mark of the Wild', 'Battle Resurrection']
  },
  {
    Name: 'Evoker',
    Emoji: MakeClassIcon('Evoker'),
    Color: '#33937F',
    Short: 'Evoker',
    Specs: [
      {
        Name: 'Augmentation',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Evoker-Augmentation')
      },
      {
        Name: 'Devastation',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Evoker-Devastation')
      },
      {
        Name: 'Preservation',
        Role: 'Healer',
        Emoji: MakeSpecIcon('Evoker-Preservation')
      }
    ],
    Roles: ['DPS', 'Healer'],
    Armor: 'Mail',
    Tier: 'Zenith',
    Buffs: ['Blessing of the Bronze', 'Bloodlust']
  },
  {
    Name: 'Hunter',
    Emoji: MakeClassIcon('Hunter'),
    Color: '#AAD372',
    Short: 'Hunter',
    Specs: [
      {
        Name: 'Beast Mastery',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Hunter-BeastMastery')
      },
      {
        Name: 'Marksmanship',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Hunter-Marksmanship')
      },
      {
        Name: 'Survival',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Hunter-Survival')
      }
    ],
    Roles: ['DPS'],
    Armor: 'Mail',
    Tier: 'Mystic',
    Buffs: ["Hunter's Mark", 'Bloodlust']
  },
  {
    Name: 'Mage',
    Emoji: MakeClassIcon('Mage'),
    Color: '#3FC7EB',
    Short: 'Mage',
    Specs: [
      {
        Name: 'Arcane',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Mage-Arcane')
      },
      {
        Name: 'Fire',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Mage-Fire')
      },
      {
        Name: 'Frost',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Mage-Frost')
      }
    ],
    Roles: ['DPS'],
    Armor: 'Cloth',
    Tier: 'Mystic',
    Buffs: ['Arcane Intellect', 'Bloodlust']
  },
  {
    Name: 'Monk',
    Emoji: MakeClassIcon('Monk'),
    Color: '#00FF98',
    Short: 'Monk',
    Specs: [
      {
        Name: 'Brewmaster',
        Role: 'Tank',
        Emoji: MakeSpecIcon('Monk-Brewmaster')
      },
      {
        Name: 'Mistweaver',
        Role: 'Healer',
        Emoji: MakeSpecIcon('Monk-Mistweaver')
      },
      {
        Name: 'Windwalker',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Monk-Windwalker')
      }
    ],
    Roles: ['Tank', 'DPS', 'Healer'],
    Armor: 'Leather',
    Tier: 'Zenith',
    Buffs: ['Mystic Touch']
  },
  {
    Name: 'Paladin',
    Emoji: MakeClassIcon('Paladin'),
    Color: '#F48CBA',
    Short: 'Pala',
    Specs: [
      {
        Name: 'Holy',
        Role: 'Healer',
        Emoji: MakeSpecIcon('Paladin-Holy')
      },
      {
        Name: 'Protection',
        Role: 'Tank',
        Emoji: MakeSpecIcon('Paladin-Protection')
      },
      {
        Name: 'Retribution',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Paladin-Retribution')
      }
    ],
    Roles: ['Tank', 'DPS', 'Healer'],
    Armor: 'Plate',
    Tier: 'Venerated',
    Buffs: ['Devotion Aura', 'Retribution Aura', 'Battle Resurrection']
  },
  {
    Name: 'Priest',
    Emoji: MakeClassIcon('Priest'),
    Color: '#FFFFFF',
    Short: 'Priest',
    Specs: [
      {
        Name: 'Discipline',
        Role: 'Healer',
        Emoji: MakeSpecIcon('Priest-Discipline')
      },
      {
        Name: 'Holy',
        Role: 'Healer',
        Emoji: MakeSpecIcon('Priest-Holy')
      },
      {
        Name: 'Shadow',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Priest-Shadow')
      }
    ],
    Roles: ['DPS', 'Healer'],
    Armor: 'Cloth',
    Tier: 'Venerated',
    Buffs: ['Fortitude']
  },
  {
    Name: 'Rogue',
    Emoji: MakeClassIcon('Rogue'),
    Color: '#FFF468',
    Short: 'Rogue',
    Specs: [
      {
        Name: 'Assassination',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Rogue-Assassination')
      },
      {
        Name: 'Outlaw',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Rogue-Outlaw')
      },
      {
        Name: 'Subtlety',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Rogue-Subtlety')
      }
    ],
    Roles: ['DPS'],
    Armor: 'Leather',
    Tier: 'Zenith',
    Buffs: ['Atrophic Poison']
  },
  {
    Name: 'Shaman',
    Emoji: MakeClassIcon('Shaman'),
    Color: '#0070DD',
    Short: 'Shaman',
    Specs: [
      {
        Name: 'Elemental',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Shaman-Elemental')
      },
      {
        Name: 'Enhancement',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Shaman-Enhancement')
      },
      {
        Name: 'Restoration',
        Role: 'Healer',
        Emoji: MakeSpecIcon('Shaman-Restoration')
      }
    ],
    Roles: ['DPS', 'Healer'],
    Armor: 'Mail',
    Tier: 'Venerated',
    Buffs: ['Bloodlust', 'Skyfury']
  },
  {
    Name: 'Warlock',
    Emoji: MakeClassIcon('Warlock'),
    Color: '#8788EE',
    Short: 'Lock',
    Specs: [
      {
        Name: 'Affliction',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Warlock-Affliction')
      },
      {
        Name: 'Demonology',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Warlock-Demonology')
      },
      {
        Name: 'Destruction',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Warlock-Destruction')
      }
    ],
    Roles: ['DPS'],
    Armor: 'Cloth',
    Tier: 'Dreadful',
    Buffs: ['Battle Resurrection', 'Healthstone']
  },
  {
    Name: 'Warrior',
    Emoji: MakeClassIcon('Warrior'),
    Color: '#C69B6D',
    Short: 'War',
    Specs: [
      {
        Name: 'Arms',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Warrior-Arms')
      },
      {
        Name: 'Fury',
        Role: 'DPS',
        Emoji: MakeSpecIcon('Warrior-Fury')
      },
      {
        Name: 'Protection',
        Role: 'Tank',
        Emoji: MakeSpecIcon('Warrior-Protection')
      }
    ],
    Roles: ['Tank', 'DPS'],
    Armor: 'Plate',
    Tier: 'Zenith',
    Buffs: ['Battle Shout']
  }
];

const tankClasses: WoWClass[] = Classes.filter((wowClass) => {
  return wowClass.Roles?.includes('Tank');
});
