import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import ManagementProductSingle from './content/dashboards/Products/single';
import ManagementProductCreate from './content/dashboards/Products/create';
import ManagementProducts from './content/dashboards/Products';
import CartManagement from './content/dashboards/Products/cart';
import ProtectedRoute from './ProtectedRoute';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

const Disclaimer = Loader(lazy(() => import('src/content/pages/Disclaimer')));

const ToS = Loader(lazy(() => import('src/content/pages/ToS')));

const Privacy = Loader(lazy(() => import('src/content/pages/Privacy')));

const AboutUs = Loader(lazy(() => import('src/content/pages/AboutUs')));

const ContactUs = Loader(lazy(() => import('src/content/pages/ContactUs')));

// Dashboards
const Shop = Loader(lazy(() => import('src/content/dashboards/Products/shop')));

const Profile = Loader(lazy(() => import('src/content/dashboards/Profile')));

const Premium = Loader(lazy(() => import('src/content/dashboards/Premium')));

const Admin = Loader(lazy(() => import('src/content/dashboards/Admin')));

const Reviews = Loader(lazy(() => import('src/content/dashboards/Reviews')));

const Appeals = Loader(lazy(() => import('src/content/dashboards/Appeals')));

const Chats = Loader(lazy(() => import('src/content/dashboards/Chats')));

// Applications
const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Offers = Loader(lazy(() => import('src/content/applications/Offers')));
const Leaderboards = Loader(
  lazy(() => import('src/content/applications/Leaderboards'))
);
const Statistics = Loader(
  lazy(() => import('src/content/dashboards/Statistics'))
);
const PosterHub = Loader(
  lazy(() => import('src/content/applications/PosterHub'))
);
const Guides = Loader(lazy(() => import('src/content/pages/Guides')));
const Karma = Loader(lazy(() => import('src/content/applications/Karma')));
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);
const Notifications = Loader(
  lazy(() => import('src/content/applications/Users/notifications'))
);
const ShopHome = Loader(lazy(() => import('src/content/dashboards/ShopHome')));

// Status
const PaymentFail = Loader(
  lazy(() => import('src/content/pages/Status/Payment/Fail'))
);
const PaymentSuccess = Loader(
  lazy(() => import('src/content/pages/Status/Payment/Success'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Login = Loader(lazy(() => import('src/content/pages/Status/Login')));
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'disclaimer',
        element: <Disclaimer />
      },
      {
        path: 'tos',
        element: <ToS />
      },
      {
        path: 'privacy_policy',
        element: <Privacy />
      },
      {
        path: 'about_us',
        element: <AboutUs />
      },
      {
        path: 'contact_us',
        element: <ContactUs />
      },
      {
        path: 'discord_oauth',
        element: <Overview />
      },
      {
        path: 'blizzard_oauth',
        element: <Profile />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'payment',
        children: [
          {
            path: 'success',
            element: <PaymentSuccess />
          },
          {
            path: 'fail',
            element: <PaymentFail />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'guides',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="register" replace />
      },
      {
        path: ':category',
        element: <Guides />
      }
    ]
  },
  {
    path: 'dashboards',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="profile" replace />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'premium',
        element: <Premium />
      },
      {
        path: 'messenger',
        element: <Messenger />
      },
      {
        path: 'chats',
        element: <Chats />
      },
      {
        path: 'admin',
        element: <Admin />
      },
      {
        path: 'statistics',
        children: [
          {
            path: '',
            element: <Navigate to="mystats" replace />
          },
          {
            path: ':category',
            element: <Statistics />
          }
        ]
      }
    ]
  },
  // TODO: use this route for shop pages
  {
    path: 'shop',
    element: <SidebarLayout />,
    children: [
      // {
      //   path: '',
      //   element: <Shop />
      // },
      {
        path: '',
        element: <ManagementProducts />
      },
      {
        path: 'profile',
        element: <ShopHome />
      },
      {
        path: 'create',
        element: <ManagementProductCreate />
      }
      // {
      //   path: 'cart',
      //   element: <CartManagement />
      // }
    ]
  },
  // {
  //   path: 'chats',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element: <Chats />
  //     }
  //   ]
  // },
  {
    path: 'posterhub',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <PosterHub />
      },
      {
        path: ':category',
        element: <PosterHub />
      }
    ]
  },
  {
    path: 'management',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="offers/offers" replace />
      },
      {
        path: 'offers',
        children: [
          {
            path: '',
            element: <Navigate to="offers" replace />
          },
          {
            path: ':category',
            element: <Offers />
          },
          {
            path: 'leaderboards',
            element: <Leaderboards />
          },
          {
            path: 'karma',
            element: <Karma />
          }
        ]
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="settings" replace />
          },
          // {
          //   path: 'details',
          //   element: <UserProfile />
          // },
          {
            path: 'settings',
            element: <UserSettings />
          },
          {
            path: 'notifications',
            element: <Notifications />
          },
          {
            path: 'reviews',
            element: <Reviews />
          },
          {
            path: 'appeals',
            element: <Appeals />
          }
        ]
      }
    ]
  }
];

export default routes;
