import { Middleware } from 'redux';
import {
  markRead,
  setEmergencyNotification
} from 'src/features/announcementsSlice';

const announcementsStatsMiddleware: Middleware = (storeAPI) => {
  return (next) => async (action) => {
    switch (action.type) {
      case 'announcements/markRead':
        storeAPI.dispatch(markRead(action.payload));
        break;
      case 'announcements/emergencyAcknowledge':
        storeAPI.dispatch(setEmergencyNotification(undefined));
        break;
      default:
        break;
    }
    return next(action);
  };
};

export default announcementsStatsMiddleware;
